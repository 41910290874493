import React, { Component } from 'react';
import { Row, Col } from 'antd';
import Error from './Error';
import MyEmpty from './MyEmpty';
import Loading from './Loading';
import CategoryDao from "../dao/CategoryDao";
import CategoryItem from './CategoryItem';
import SubcategoryItem from './SubcategoryItem';
import MyBreadcrumbs from './MyBreadcrumbs';
import ContentTitle from './ContentTitle';
import './CatalogMenu.css';

class CatalogMenu extends Component {
    categoryDao = new CategoryDao();
    categories;
    myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.catalogmenu',
        },
    ];

    constructor() {
        super();
        this.state = {};
        this.getAllAldiCategoriesCallback = this.getAllAldiCategoriesCallback.bind(this);
        this.onError = this.onError.bind(this);
    }

    render() {
        // console.log('status: ' + this.state.status);
        if (this.isLoading()) {
            return this.getLoadingContent();
        }
        if (this.isError()) {
            return this.getErrorContent();
        }
        if (this.isEmpty()) {
            return this.getEmptyContent();
        }
        if (this.isReady()) {
            return this.getReadyContent();
        }
        this.start();
        return this.getLoadingContent();
    }

    onError(errorResponse) {
        console.error(errorResponse);
        this.setState({ status: 'error' });
    }

    checkAllDataReady() {
        this.setState({ status: 'ready' });
    }

    isLoading() {
        return this.state.status === 'loading';
    }

    isReady() {
        return this.state.status === 'ready';
    }

    hasSubcategories(category) {
        return category._source.BETTER + category._source.ALTAS + category._source.BAJAS == 0;
    }

    getReadyContent() {
        return (
            <div>
                <MyBreadcrumbs data={this.myBreadcrumbs} />
                <ContentTitle id="catalogmenu.title" />
                <Row>
                    {this.categories
                        .map(c => <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                            {this.hasSubcategories(c)
                                ?
                                <CategoryItem id={c._source.WGR_NR} name={c._source.WGR_BEZEICHNUNG} />
                                :
                                <SubcategoryItem id={c._source.Concatenate} name={c._source.WGR_BEZEICHNUNG} />
                            }
                        </Col>)}
                </Row>
            </div>
        )
    }

    isEmpty() {
        if (!this.products) {
            return false;
        }
        return this.products.length === 0;
    }

    getEmptyContent() {
        return <MyEmpty />
    }

    isError() {
        return this.state.status === 'error';
    }

    getErrorContent() {
        return <Error />
    }

    getAllAldiCategoriesCallback(data) {
        this.categories = data;
        this.checkAllDataReady();
    }

    start() {
        // console.log('start');
        this.categoryDao.findAllAldiSuperCategories(this.getAllAldiCategoriesCallback, this.onError);
        this.setState({ status: 'loading' });
    }

    getLoadingContent() {
        return <Loading />
    }
}

export default CatalogMenu;