import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import ContentTitle from './ContentTitle';
import MyBreadcrumbs from './MyBreadcrumbs';
import './Dashboard.css';
import { FormattedMessage } from 'react-intl';

export default function Dashboard() {
    const myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        }
    ];

    return (
        <div>
            <MyBreadcrumbs data={myBreadcrumbs} />
            <ContentTitle id="dashboard.title" />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                    <Link to="/recommendations">
                        <div className="container">
                            <div className="box">
                                <img className="image"
                                    alt="recommendations"
                                    src={`${process.env.PUBLIC_URL}/recommendations.svg`}
                                />
                                <span className="text">
                                    <FormattedMessage id="dashboard.recommendations" />
                                </span>
                            </div>
                        </div>
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                    <Link to="/catalogmenu">
                        <div className="container">
                            <div className="box">
                                <img className="image"
                                    alt="supermarket"
                                    src={`${process.env.PUBLIC_URL}/supermarket.svg`}
                                />
                                <span className="text">
                                    <FormattedMessage id="dashboard.supermarket" />
                                </span>
                            </div>
                        </div>
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                    <Link to="/orders">
                        <div className="container">
                            <div className="box">
                                <img className="image"
                                    alt="history"
                                    src={`${process.env.PUBLIC_URL}/history.svg`}
                                />
                                <span className="text">
                                    <FormattedMessage id="dashboard.history" />
                                </span>
                            </div>
                        </div>
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                    <Link to="/profile">
                        <div className="container">
                            <div className="box">
                                <img className="image"
                                    alt="profile"
                                    src={`${process.env.PUBLIC_URL}/user.svg`}
                                />
                                <span className="text">
                                    <FormattedMessage id="dashboard.profile" />
                                </span>
                            </div>
                        </div>
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                    <Link to="/shoppingList">
                        <div className="container">
                            <div className="box">
                                <img className="image"
                                    alt="shoppingcart"
                                    src={`${process.env.PUBLIC_URL}/cart.svg`}
                                />
                                <span className="text">
                                    <FormattedMessage id="dashboard.shoppingcart" />
                                </span>
                            </div>
                        </div>
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                    <Link to="/notifications">
                        <div className="container">
                            <div className="box">
                                <img className="image"
                                    alt="notifications"
                                    src={`${process.env.PUBLIC_URL}/bell.svg`}
                                />
                                <span className="text">
                                    <FormattedMessage id="dashboard.notifications" />
                                </span>
                            </div>
                        </div>
                    </Link>
                </Col>
            </Row>
        </div>
    );
}