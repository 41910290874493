import { Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import AccessTokenDao from '../dao/AccessTokenDao';
import UserDataDao from '../dao/UserDataDao';

export default function PushSubscriptionDialog() {
    const [isDialogVisible, setIsDialogVisible] = useState(false);

    // Display a subscription request dialog if the user has no subscription
    // and the permission state is not explicitly 'denied'
    useEffect(() => {
        function checkUserSubscription() {
            navigator.serviceWorker.ready
                .then(async reg => {
                    const permission = await reg.pushManager.permissionState({
                        userVisibleOnly: true
                    });
                    if (permission !== 'denied') {
                        const subscription = await reg.pushManager.getSubscription();
                        if (subscription == null) {
                            console.log('User is NOT subscribed.');
                            setIsDialogVisible(true);
                        } else {
                            console.log('User IS subscribed.');
                            updateSubscriptionOnServer(subscription);
                        }
                    } else {
                        console.log('User denied notifications.');
                    }
                });
        };

        if ('serviceWorker' in navigator && 'PushManager' in window) {
            console.log('Service Worker and Push is supported');
            checkUserSubscription();
        } else {
            console.warn('Push messaging is not supported');
        }
    }, []);

    function getVapidPublicKey() {
        return fetch(`${process.env.REACT_APP_ALDI}/vapid_public_key/`)
            .then(response => response.text())
            .then(vapidPublicKey => urlBase64ToUint8Array(vapidPublicKey));
    }

    function urlBase64ToUint8Array(base64String) {
        const padding = "=".repeat((4 - base64String.length % 4) % 4);
        // eslint-disable-next-line
        const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    function updateSubscriptionOnServer(subscription) {
        let body = {
            user_id: new UserDataDao().getAuth0Id(),
            token: new AccessTokenDao().getAuth0Token(),
            subscription: subscription
        }

        return fetch(`${process.env.REACT_APP_ALDI}/subscriptions/register/`, {
            method: 'post',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(body)
        });
    }

    function subscribeUser() {
        navigator.serviceWorker.ready
            .then(async reg => {
                const vapidPublicKey = await getVapidPublicKey();
                return reg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: vapidPublicKey
                });
            })
            .then(sub => {
                console.log('User is subscribed.', sub);
                updateSubscriptionOnServer(sub);
            })
            .catch(err => {
                console.log('Failed to subscribe the user: ', err);
            });
    };

    function unsubscribeUser() {
        navigator.serviceWorker.ready.then(reg => {
            reg.pushManager.getSubscription().then(sub => {
                if (sub) {
                    return sub.unsubscribe();
                }
            })
                .catch(error => {
                    console.log('Error unsubscribing', error);
                })
                .then(() => {
                    updateSubscriptionOnServer(null);

                    console.log('User is unsubscribed.');
                });
        });
    }

    return (
        <Popconfirm
            title="¿Desea recibir notificaciones de Preventomics?"
            visible={isDialogVisible}
            onConfirm={() => { setIsDialogVisible(false); subscribeUser() }}
            onCancel={() => setIsDialogVisible(false)}
            okText="Aceptar"
            cancelText="Cancelar"
        >
            {/* Popconfirm needs a child node */}
            <div style={{ position: 'fixed', bottom: '0', width:'100%'}} />
        </Popconfirm>
    )
} 