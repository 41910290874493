import React from 'react';
import { Dropdown, Menu } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth0 } from '../react-auth0-wrapper';
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";

const ProfileDropdownMenu = (props) => {
    const doLogout = () => {
        logout({
            returnTo: process.env.REACT_APP_URL
        });
    };

    const { logout } = useAuth0();

    const onMenuSelect = (event) => {
        if (['logout'].includes(event.key)) {
            doLogout();
        }
    };

    const dropdownMenu = (
        <Menu onClick={onMenuSelect} className="profile-dropdown-menu">
            <Menu.Item className="dropdown-item">
                <Link to="/profile" >
                    <FormattedMessage id="usermenu.myprofile" />
                </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item className="dropdown-item">
                <Link to="/orders" >
                    <FormattedMessage id="usermenu.orders" />
                </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" className="dropdown-item">
                <FormattedMessage id="usermenu.logout" />
            </Menu.Item>
        </Menu>
    );

    return <Dropdown overlay={dropdownMenu}>
        <div className="ant-dropdown-link">
            <UserOutlined className="nav-icon" style={{ height: '100%', width: 'auto' }} />
            <DownOutlined />
        </div>
    </Dropdown>
}
export default ProfileDropdownMenu;