import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ShoppingListDao from "../dao/ShoppingListDao_v2";
import UserDataDao from "../dao/UserDataDao";
import "./LoadLastShoppingList.css";

export default function LoadLastShoppingList(props) {

    const [lastShoppingList, setLastShoppingList] = useState(null);
    const [loading, setLoading] = useState(false);

    // Get the last shopping list if exists
    useEffect(() => {
        const userDataDao = new UserDataDao();
        fetch(`${process.env.REACT_APP_ALDI}/users/${userDataDao.getPreventomicsId()}/lists/`)
            .then(response => response.json())
            .then(response => {
                if (response.length > 0) {
                    setLastShoppingList(response[0])
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    function loadLastShoppingList() {
        setLoading(true);
        const shoppingListDao = new ShoppingListDao();
        const userDataDao = new UserDataDao();
        fetch(`${process.env.REACT_APP_ALDI}/users/${userDataDao.getPreventomicsId()}/lists/${lastShoppingList['_id']}/`)
            .then(response => response.json())
            .then(items => 
                Promise.all(
                    items.map(item => 
                        shoppingListDao.add(item['id'], item['quantity'])
                    )
                )
            )
            .then(() => props.setProducts())
            .catch(error => {
                console.log(error);
            });
    }

    if (lastShoppingList == null) {
        return null;
    }

    return (
        <Button
            className="load-last-shoppinglist"
            type="primary"
            onClick={loadLastShoppingList}
            size="large"
            loading={loading}
        >
            <FormattedMessage id="shoppinglist.repeat.last.shoppinglist" />
        </Button>
    )
}