import React from 'react';
import { FormattedMessage } from 'react-intl';
import './Ingredients.css';

export default class Ingredients extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }

    getIngredients() {
        let ingredients = "";
        for (var i = 1; i <= 34; i++) {
            const ingredient = this.state.data._source['Ingrediente ' + i];
            if (ingredient === undefined) {
                continue;
            }
            if (ingredient === null) {
                continue;
            }
            if (ingredient === "") {
                continue;
            }
            ingredients += ingredient + '; ';
        }
        return ingredients;
    }

    render() {
        return <div id="ingredients">
            <div id="ingredients-title">
                <FormattedMessage id="ingredients.title" />
            </div>
            <div id="ingredients-data">{this.getIngredients()}</div>
        </div>
    }
}