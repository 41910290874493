import React, { Component } from 'react';
import ContentTitle from './ContentTitle';
import { FormattedMessage } from 'react-intl';
import UserDataDao from '../dao/UserDataDao';
import FormDao from '../dao/FormDao';
import Loading from './Loading';
import { Col, Row, Table, Typography } from 'antd';
import Empty from './MyEmpty';
import MyBreadcrumbs from './MyBreadcrumbs';

export default class Anthropometrics extends Component {
    userDataDao = new UserDataDao();
    formDao = new FormDao();
    myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.myprofile',
            url: '/profile',
        },
        {
            label: 'mybreadcrumbs.anthropometrics',
        },
    ];
    source;
    datasource;
    columns = [
        {
            title: <FormattedMessage id="forms.anthropometrics.measure"></FormattedMessage>,
            dataIndex: 'label',
            key: 'label',
            render: text => <Typography.Text style={{ fontSize: 'medium' }}>{text}</Typography.Text>
        },
        {
            title: <FormattedMessage id="forms.anthropometrics.value"></FormattedMessage>,
            dataIndex: 'value',
            key: 'value',
            render: text => <Typography.Text style={{ fontSize: 'medium' }}>{text}</Typography.Text>
        },
    ];

    constructor(props) {
        super(props);
        this.state = {};
        this.start = new URLSearchParams(props.location.search).get("start");
        this.getAnthropometricsCallback = this.getAnthropometricsCallback.bind(this);
        this.onError = this.onError.bind(this);
    }

    getAnthropometricsCallback(data) {
        this.source = data;
        this.datasource = Object.keys(data.contents).filter(key => key !== "physicalActivity").map(f => ({
            label: <FormattedMessage id={f}></FormattedMessage>,
            value: Number.parseFloat(data.contents[f]).toFixed(2)
        }));
        this.setState({ status: 'ready' });
    }

    onError(errorData) {
        console.log(errorData);
    }

    getEmptyContent() {
        return <Row >
            <Col span={24}>
                <Empty />
            </Col>
        </Row>
    }

    render() {
        // console.log('data: ' + JSON.stringify(this.state.data));
        if (this.state.status === 'ready') {
            if (this.datasource.length === 0) {
                return this.getEmptyContent();
            }
            return (
                <div>
                    <MyBreadcrumbs data={this.myBreadcrumbs} />
                    <ContentTitle
                        id={ this.source.description }
                        defaultMessage={ `${this.source.description} {version}` }
                        values={{ version: this.source.version ? 'v' + this.source.version : '' }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', overflow: 'auto' }}>
                        <Table columns={this.columns} dataSource={this.datasource} bordered={true} pagination={false} size="middle" />
                    </div>
                </div>
            )
        }
        const userId = this.userDataDao.getPreventomicsId();
        this.formDao.getAnthropometrics(userId, this.start, this.getAnthropometricsCallback, this.onError);
        return this.getLoadingContent();
    }

    getLoadingContent() {
        return <Loading />
    }
}