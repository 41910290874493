import React, { Component } from 'react';
import './Logo.css';

class Logo extends Component {

    render() {
        return <div id="logo">
            <img id="logo" alt="logo" src={process.env.PUBLIC_URL + '/PREVENTOMICS_logo_big.png'} />
        </div>
    }
}

export default Logo;