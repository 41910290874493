import React, { Component } from 'react';
import { Badge } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import ShoppingListDao from '../dao/ShoppingListDao';

export default class ShoppingCartIcon extends Component {
    shoppingListDao = new ShoppingListDao();

    render() {
        console.log('TODO: discomment and fix it');
        return <Badge
          count={ this.props.shoppingListCount }
        >
            <ShoppingCartOutlined style={{ height: 'auto', width: '100%' }} />
        </Badge>
    }
}