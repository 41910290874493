import CategoryDao from "../dao/CategoryDao";

class CatalogSubcategoriesBo {
    categoryDao = new CategoryDao();

    getAllAldiCategories(callback, error) {
        this.categoryDao.findAllAldiCategories(callback, error);
    }

    filterSubcategories(categoryJson, id) {
        // console.log('id: ' + id + '; WGR_NR: ' + categoryJson._source.WGR_NR);
        return '' + categoryJson._source.WGR_NR === '' + id
            && !(categoryJson._source.WGR_UGR1_NR === '0'
                && categoryJson._source.WGR_UGR2_NR === '0'
                && categoryJson._source.WGR_UGR3_NR === '0');
    }

    findCategory(categoryJson, id) {
        return '' + categoryJson._source.WGR_NR === '' + id
            && categoryJson._source.WGR_UGR1_NR === '0'
                && categoryJson._source.WGR_UGR2_NR === '0'
                && categoryJson._source.WGR_UGR3_NR === '0';
    }
}

export default CatalogSubcategoriesBo;