import React, { Component } from 'react';
import { TagOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import './SubcategoryItem.css';

class SubcategoryItem extends Component {

    render() {
        return <Link to={'/subcategory-products/' + this.props.id}>
            <div id="subcategory-container">
                <div id="subcategory">
                    <TagOutlined className="subcategory-icon-size" type="tag" />
                    <span>{this.props.name}</span>
                </div>
            </div>
        </Link>
    }
}

export default SubcategoryItem;