import AccessTokenDao from './AccessTokenDao';

export default class UserDataDao {
    accessTokenDao = new AccessTokenDao();

    key = 'userdata';

    get() {
        return JSON.parse(localStorage.getItem(this.key));
    }

    save(userdata) {
        localStorage.setItem(this.key, JSON.stringify(userdata));
    }

    getAuth0Id() {
        return this.get().sub;
    }

    getPreventomicsId() {
        return this.get().sub.split("|")[1];
    }

    getDssUser(userId) {
        const url = process.env.REACT_APP_DSS + '/user/' + userId;
        const data = {
            headers: {
                'authorization': this.accessTokenDao.get()
            }
        };

        return fetch(url, data).then(response => response.json());
    }
}