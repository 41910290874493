import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import './AppMenu.css';
import { Col } from 'antd';

class AppMenu extends Component {

    render() {
        return <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div id="appmenu">
                <NavLink activeClassName="selected" className='menuitem' to='/recommendations'>
                    <FormattedMessage id="appmenu.recommendations" />
                </NavLink>
                <NavLink activeClassName="selected" className='menuitem' to="/catalogmenu">
                    <FormattedMessage id="appmenu.catalog" />
                </NavLink>
                <NavLink activeClassName="selected" className='menuitem' to='/orders'>
                    <FormattedMessage id="appmenu.consumptions" />
                </NavLink>
                <NavLink activeClassName="selected" className='menuitem' to='/faq'>
                    <FormattedMessage id="appmenu.faq" />
                </NavLink>
            </div>
        </Col>
    }
}

export default AppMenu;
