import React from 'react';
import { Row, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import UserDataDao from '../dao/UserDataDao';
import ContentTitle from './ContentTitle';
import Loading from './Loading';
import MyBreadcrumbs from './MyBreadcrumbs';
import { FormattedMessage } from 'react-intl';
import { useServingsClasses } from '../hooks/useServingsClasses';
import './RecommendedClasses.css';

export default function RecommendedClasses() {
    const userDataDao = new UserDataDao();
    const email = userDataDao.get().email;
    const servingsClasses = useServingsClasses();

    const myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.recommendedproducts',
        },
    ];

    if (servingsClasses === {}) {
        return <Loading />;
    }
    return (
        <div>
            <MyBreadcrumbs data={myBreadcrumbs} />
            <ContentTitle id="recommendedproducts.title" />
            <Typography className="recommendations-text">
                <Typography.Paragraph>
                    <FormattedMessage id="recommendedproducts.text" values={{username: email}}></FormattedMessage>
                </Typography.Paragraph>
            </Typography>
            <Row>
                {Object.entries(servingsClasses).map(([c_code, c_value]) => (
                    <Col key={c_code} xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <Link to={`/recommendations/${c_code}/foods`}>
                            <div className="servings-class-container">
                                <div className="block">
                                    <div className="image-container">
                                        <img
                                            className="image"
                                            alt={c_code.substring(2, c_code.length)}
                                            src={`${process.env.PUBLIC_URL}/${c_value['image']}`}
                                        />
                                    </div>
                                    <FormattedMessage id={c_code}></FormattedMessage>
                                    <FormattedMessage
                                        id="recommended.servings"
                                        values={{
                                            b: msg => <b>{msg}</b>,
                                            min: c_value['health_min_serving'],
                                            max: c_value['health_max_serving'],
                                            time: c_value['temporal_measure']
                                        }}
                                    />
                                </div>
                            </div>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
}