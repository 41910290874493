import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from './react-auth0-wrapper';
import { BrowserRouter as Router } from 'react-router-dom';

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

console.log('checking args: REACT_APP_AUTH0_DOMAIN:  ' + process.env.REACT_APP_AUTH0_DOMAIN);
console.log('checking args: REACT_APP_AUTH0_CLIENT_ID:  ' + process.env.REACT_APP_AUTH0_CLIENT_ID);
console.log('checking args: REACT_APP_URL:  ' + process.env.REACT_APP_URL);
console.log('checking args: REACT_APP_AUTH0_AUDIENCE:  ' + process.env.REACT_APP_AUTH0_AUDIENCE);

// By defining the unload event handler the Firefox cache is disabled.
// This is needed to avoid the occasional out of sync between the URL and the displayed page
// when navigating using the address bar and the back/forward buttons.
// See https://github.com/ReactTraining/react-router/issues/837 and
// https://developer.mozilla.org/es/docs/Web/API/GlobalEventHandlers/onunload
window.addEventListener('unload', function(){});

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    redirect_uri={process.env.REACT_APP_URL}
    onRedirectCallback={onRedirectCallback}>
    <Router>
      <App />
    </Router>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

