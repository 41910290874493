import React, { Component } from 'react';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

export default class FlagDropdownMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.currentLanguage
        }
        this.onLanguageSelect = this.onLanguageSelect.bind(this);
    }

    onLanguageSelect = (event) => {
        this.setState({ language: event.key });
        this.props.onLanguageSelect(event.key);
    }

    render() {
        const dropdownMenu = (
            <Menu className="profile-dropdown-menu" onClick={this.onLanguageSelect} selectedKeys={[this.state.language]}>
                <Menu.Item key="es" className="dropdown-item">
                    <FormattedMessage id="languages.spanish" />
                </Menu.Item>
                <Menu.Item key="en-GB" className="dropdown-item">
                    <FormattedMessage id="languages.english" />
                </Menu.Item>
            </Menu>
        );

        return (
            <Dropdown overlay={dropdownMenu}>
                <div style={{ cursor: 'pointer' }} className="ant-dropdown-link">
                    <span style={{ margin: '0em 0.25em 0em 0em' }}>
                        <FormattedMessage id="languageselector.text" />
                    </span>
                    <DownOutlined />
                </div>
            </Dropdown>
        );
    }
};

FlagDropdownMenu.defaultProps = {
    currentLanguage: 'es'
}
