import React from 'react';
import { Row, Col, Typography, message, Divider, Modal } from 'antd';
import { PrinterOutlined, CloseOutlined, SaveOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
import PrintableList from './PrintableList';
import ShoppingListDao from '../dao/ShoppingListDao_v2';
import UserDataDao from '../dao/UserDataDao';
import CartProductCounter from '../dao/CartProductCounter';
import ProductDao from '../dao/ProductDao';
import Empty from './MyEmpty';
import ContentTitle from './ContentTitle';
import { FormattedMessage, injectIntl } from 'react-intl';
import MyBreadcrumbs from './MyBreadcrumbs';
import './ShoppingList.css'
import ShoppingSubList from './ShoppingSubList';
import RecommendationsDao from '../dao/RecommendationsDao';
import { withUseServingsClassesHook } from '../HOC/withUseServingsClassesHook';
import LoadLastShoppingList from './LoadLastShoppingList';
import Loading from './Loading';

class ShoppingList extends React.Component {
    recommendationsDao = new RecommendationsDao();
    productDao = new ProductDao();
    shoppingListDao = new ShoppingListDao();
    userDataDao = new UserDataDao();

    userId = this.userDataDao.getPreventomicsId()
    cartProductCounter = new CartProductCounter();
    myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.shoppinglist',
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            howMany: {},
            cluster: "",
            recommendedCategories: [],
            notRecommendedCategories: [],
            loading: false
        };
        this.handleRemoveClickOne = this.handleRemoveClickOne.bind(this);
        this.handleAddClickOne = this.handleAddClickOne.bind(this);
        this.handleRemoveClickAll = this.handleRemoveClickAll.bind(this);
        this.showConfirmSaveList = this.showConfirmSaveList.bind(this);
        this.handleSaveList = this.handleSaveList.bind(this);
        this.handleRemoveAll = this.handleRemoveAll.bind(this);
        this.setProducts = this.setProducts.bind(this);
    }

    componentDidMount() {
        this.setProducts();

        this.recommendationsDao.getRecommendations(this.userId)
        .then(data => {
            this.setState({
                cluster: data['contents']['Cluster'],
                recommendedCategories: data['contents']['Food_recommendation']['Recommended_food'],
                notRecommendedCategories: data['contents']['Food_recommendation']['Disallowed_food']
            })
        })
        .catch(error => {
            console.log(error);
        });

        // Detect when the user navigates away so we can destroy
        // the eventual confirmation modal
        this.unblock = this.props.history.block(() => {
            Modal.destroyAll();
        });
    }

    componentWillUnmount() {
        this.unblock();
    }

    setProducts() {
        this.setState({loading: true});
        this.shoppingListDao.get()
            .then(howMany => {
                // Product quantities
                this.setState({howMany: howMany});

                // Total number of products of the shopping list
                this.props.setShoppingListCount({value: this.shoppingListCount(howMany)});

                //Product details
                return Promise.all(Object.keys(howMany).map(key => this.productDao.findById(key)));
            })
            .then(prods => {
                this.setState({products: prods, loading: false});
            });
    }

    shoppingListCount(howMany) {
        return Object.values(howMany).reduce((count, val) => count + val, 0)
    }

    handleRemoveClickOne(productId) {
        this.shoppingListDao.remove(productId)
        this.setState(state => {
            let howMany = {...state.howMany, [productId]: state.howMany[productId] - 1};
            return {
                howMany: howMany,
                products: state.products.filter(product => howMany[product._id])
            };
        });
        this.cartProductCounter.refresh();
        this.props.setShoppingListCount({value: -1, increment: true});
    }

    handleAddClickOne(productId) {
        this.shoppingListDao.add(productId);
        this.setState(state => {
            let howMany = {...state.howMany, [productId]: state.howMany[productId] + 1};
            return {howMany: howMany};
        });
        this.cartProductCounter.refresh();
        this.props.setShoppingListCount({value: 1, increment: true});
    }

    handleRemoveClickAll(productId) {
        this.setState(state => {
            let howMany = {...state.howMany, [productId]: 0};
            this.shoppingListDao.remove(productId, state.howMany[productId]);
            this.props.setShoppingListCount({value: -state.howMany[productId], increment: true});
            return {
                howMany: howMany,
                products: state.products.filter(product => howMany[product._id])
            };
        });
        this.cartProductCounter.refresh();
    }

    showConfirmSaveList() {
        const { intl } = this.props;
        Modal.confirm({
            title: intl.formatMessage({ id: "shoppinglist.warningmessage" }),
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                this.handleSaveList();
            },
            okText: intl.formatMessage({ id: "shoppinglist.ok" }),
            cancelText: intl.formatMessage({ id: "shoppinglist.cancel" })
        });
    }

    handleSaveList() {
        const { intl } = this.props;
        const key = 'savingMessage';
        const data = {
            date: new Date().toISOString(),
            list_items: this.state.products.map(product =>
                ({
                    id: product['_id'],
                    ...product['_source'],
                    quantity: this.state.howMany[product._id]
                })
            )
        };
        
        message.loading({content: intl.formatMessage({id: 'shoppinglist.savinglist'}), key, duration: 0});

        fetch(`${process.env.REACT_APP_ALDI}/users/${this.userId}/lists/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (response.ok) {
                message.success({content: intl.formatMessage({id: 'shoppinglist.savedlist'}), key});
                this.handleRemoveAll();
                this.props.history.push("/");
            } else {
                message.error({content: 'Error', key})
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    handleRemoveAll() {
        this.shoppingListDao.removeAll();
        this.setState({howMany: {}, products: []});
        this.cartProductCounter.refresh();
        this.props.setShoppingListCount({value: 0});
    }

    filter(product) {
        return this.state.howMany[product._id] && this.state.howMany[product._id] !== null;
    }

    getContent() {
        let recommendedProducts = [];
        let notRecommendedProducts = [];
        let restofProducts = [];
        if (this.state.cluster.toLowerCase() !== "control") {
            recommendedProducts = this.state.products.filter(product => this.state.recommendedCategories.includes(product._source.p_code));
            notRecommendedProducts = this.state.products.filter(product => this.state.notRecommendedCategories.includes(product._source.p_code));
            restofProducts = this.state.products.filter(product => !(recommendedProducts.includes(product) || notRecommendedProducts.includes(product)));
        } else {
            restofProducts = this.state.products;
        }
        return (
            <div style={{ minHeight: '20em' }}>
                <MyBreadcrumbs data={this.myBreadcrumbs} />
                <ContentTitle id='shoppinglist.title' />
                <Row style={{ display: 'flex', justifyContent: 'flex-end', margin: '0px 0px 1em 0px' }}>
                    <Col span={24} >
                        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0em 2em 0em 0em' }}>
                            <div className='shoppinglist-options' onClick={this.showConfirmSaveList}>
                                <SaveOutlined />&nbsp;
                                <FormattedMessage id="shoppinglist.savelist" />
                            </div>
                            <ReactToPrint trigger={() => <div className='shoppinglist-options'>
                                <PrinterOutlined />&nbsp;
                                <FormattedMessage id="shoppinglist.printlist" />
                            </div>} content={() => this.componentRef} />
                            <div className='shoppinglist-options' onClick={this.handleRemoveAll}>
                                <CloseOutlined />&nbsp;
                                <FormattedMessage id="shoppinglist.removelist" />
                            </div>
                        </div>
                    </Col>
                </Row>

                <ShoppingSubList
                    products={restofProducts}
                    howMany={this.state.howMany}
                    onClickRemoveOne={this.handleRemoveClickOne}
                    onClickAddOne={this.handleAddClickOne}
                    onClickRemoveAll={this.handleRemoveClickAll}
                />

                <ShoppingSubList
                    products={recommendedProducts}
                    howMany={this.state.howMany}
                    onClickRemoveOne={this.handleRemoveClickOne}
                    onClickAddOne={this.handleAddClickOne}
                    onClickRemoveAll={this.handleRemoveClickAll}
                    title={
                        <Typography.Title level={4} style={{padding: '1em'}}>
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                            <FormattedMessage id="shoppinglist.recommended" />
                        </Typography.Title>
                    }
                />

                <ShoppingSubList
                    products={notRecommendedProducts}
                    howMany={this.state.howMany}
                    onClickRemoveOne={this.handleRemoveClickOne}
                    onClickAddOne={this.handleAddClickOne}
                    onClickRemoveAll={this.handleRemoveClickAll}
                    title={
                        <Typography.Title level={4} style={{padding: '1em'}}>
                            <CloseCircleTwoTone twoToneColor="red" />
                            <FormattedMessage id="shoppinglist.notrecommended" />
                        </Typography.Title>
                    }
                />

                <Divider />

                <ContentTitle id="shoppinglist.recommendedproducts.title" />
                <Row>
                    {Object.entries(this.props.servingsClasses).map(([c_code, c_value]) => (
                        <Col key={c_code} xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                            <div className="shopping-servings-class-container">
                                <div className="block">
                                    <div className="image-container">
                                        <img
                                            className="image"
                                            alt={c_code.substring(2, c_code.length)}
                                            src={`${process.env.PUBLIC_URL}/${c_value['image']}`}
                                        />
                                    </div>
                                    <FormattedMessage id={c_code}></FormattedMessage>
                                    <FormattedMessage
                                        id="recommended.servings"
                                        values={{
                                            b: msg => <b>{msg}</b>,
                                            min: c_value['health_min_serving'],
                                            max: c_value['health_max_serving'],
                                            time: c_value['temporal_measure']
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>

                <div style={{ display: 'none' }}>
                    <PrintableList products={this.state.products} howMany={this.state.howMany} ref={el => (this.componentRef = el)} />
                </div>
            </div>
        );
    }

    getEmptyContent() {
        return (
            <div style={{ minHeight: '20em' }}>
                <MyBreadcrumbs data={this.myBreadcrumbs} />
                <ContentTitle id='shoppinglist.title' />
                <Row gutter={[0, 48]} justify="center">
                    <Col>
                        <Empty />
                    </Col>
                </Row>
                <Row gutter={[0, 48]} justify="center">
                    <Col>
                        <LoadLastShoppingList setProducts={this.setProducts}/>
                    </Col>
                </Row>
            </div>
        );
    }

    getLoadingContent() {
        return <Loading />;
    }

    render() {
        if (this.state.loading) {
            return this.getLoadingContent();
        }
        if (this.shoppingListCount(this.state.howMany) === 0) {
            return this.getEmptyContent();
        }
        return this.getContent();
    }
}

export default injectIntl(withUseServingsClassesHook(ShoppingList));