import React, { Component } from 'react';
import { Input } from 'antd';
import { injectIntl } from 'react-intl';

const { Search } = Input;

class SearchInput extends Component {

    constructor(props) {
        super(props);
        this.state = { value: ''}
        this.ref = React.createRef();
        this.autofocus = this.autofocus.bind(this);
        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.autofocus();
    }

    componentDidUpdate() {
        this.autofocus();
    }   

    autofocus() {
        if (this.props.visible) {
            this.ref.current.focus();
        }
    }

    onChange({ target: { value } }) {
        this.setState({ value });
    };

    submit(value) {
        if (value !== '') {
            this.props.history.push('/searchResults?searchTerm=' + value);
            this.setState({value: ''});
            if (this.props.onClick) {
                this.props.onClick();
            }
        }
    }

    render() {
        const { intl } = this.props;
        return (
        <Search
          ref={this.ref}
          placeholder={intl.formatMessage({id: "search.placeholder"})}
          value={ this.state.value }
          onChange={ this.onChange }
          onSearch={ this.submit }
        />
        )
    }
}

export default injectIntl(SearchInput);