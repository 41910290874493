import React, { Component } from "react";
import { Menu, Tooltip } from "antd";
import ProfileDropdownMenu from './ProfileDropdownMenu'
import SearchInput from './SearchInput';
import ShoppingCartIcon from './ShoppingCartIcon';
import { Link } from "react-router-dom";
import Bell from './Bell';
import { FormattedMessage } from "react-intl";
import './SessionMenu.css';

export default class SessionMenu extends Component {


    // handleMenuClick = (event) => {
    //     if (['shoppingList'].includes(event.key)) {
    //         this.props.onTabChange(event.key);
    //         return;
    //     }
    // }

    handleLogout = () => {
        // console.log('logout!');
        this.props.handleLogout();
    }

    render() {
        const menuItems = [
            <Menu.Item key="bell" >
                <Tooltip placement="bottom" title={<FormattedMessage id="sessionmenu.notifications" />}>
                    <Link to={'/notifications'} >
                        <Bell count={this.props.notificationsCount} />
                    </Link>
                </Tooltip>
            </Menu.Item>,
            <Menu.Item key="shoppingList" >
                <Tooltip placement="bottom" title={<FormattedMessage id="sessionmenu.shoppinglist" />}>
                    <Link to={'/shoppingList'} >
                        <ShoppingCartIcon shoppingListCount={this.props.shoppingListCount} />
                    </Link>
                </Tooltip>
            </Menu.Item>,
            <Menu.Item key="profile" className="profile-menu">
                <ProfileDropdownMenu />
            </Menu.Item>
        ];

        return <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0em 1em 0em 0em' }}>
            <SearchInput history={this.props.history} />
            <Menu className="inherit-backgroundcolor" mode="horizontal" selectedKeys={["/"]} style={{ borderBottom: '0px', }} onClick={this.handleMenuClick}>
                {menuItems}
            </Menu>
        </div>
    }
}