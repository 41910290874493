import React, { Component } from 'react';
import MyEmpty from './MyEmpty';
import SubcategoryItem from './SubcategoryItem';
import CatalogSubcategoriesBo from './CatalogSubcategoriesBo';
import CategoryDao from '../dao/CategoryDao';
import Error from './Error';
import Loading from './Loading';
import { Row, Col } from 'antd';
import MyBreadcrumbs from './MyBreadcrumbs';
import ContentTitle from './ContentTitle';

class CatalogSubcategories extends Component {
    catalogSubcategoriesBo = new CatalogSubcategoriesBo();
    categoryDao = new CategoryDao();
    categories;
    counter = 0;
    myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.catalogmenu',
            url: '/catalogmenu',
        },
    ];

    constructor(props) {
        super();
        this.state = {};
        this.categoryId = props.match.params.categoryId;
        this.getAllAldiCategoriesCallback = this.getAllAldiCategoriesCallback.bind(this);
        this.onError = this.onError.bind(this);
    }

    render() {
        // console.log('status: ' + this.state.status);
        if (this.isLoading()) {
            return this.getLoadingContent();
        }
        if (this.isError()) {
            return this.getErrorContent();
        }
        if (this.isEmpty()) {
            return this.getEmptyContent();
        }
        if (this.isReady()) {
            return this.getReadyContent();
        }
        this.start();
        return this.getLoadingContent();
    }

    onError(errorResponse) {
        console.error(errorResponse);
        this.setState({ status: 'error' });
    }

    checkAllDataReady() {
        this.setState({ status: 'ready' });
    }

    isLoading() {
        return this.state.status === 'loading';
    }

    getLoadingContent() {
        return <Loading />
    }

    isReady() {
        return this.state.status === 'ready';
    }

    getReadyContent() {
        let categoryName = this.categories.find(c => this.catalogSubcategoriesBo.findCategory(c, this.categoryId))._source.WGR_BEZEICHNUNG;
        const myDynamicBreadcrumbs = this.myBreadcrumbs.concat({ 'label': categoryName });
        
        return (
            <div>
                <MyBreadcrumbs data={myDynamicBreadcrumbs} />
                <ContentTitle id={categoryName} />
                <Row>
                    {this.categories
                        .filter(c => this.catalogSubcategoriesBo.filterSubcategories(c, this.categoryId))
                        .map(c => <Col key={this.counter++} xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                            <SubcategoryItem id={c._source.Concatenate} name={c._source.WGR_BEZEICHNUNG} />
                        </Col>)}
                </Row>
            </div>
        )
    }

    isEmpty() {
        if (!this.products) {
            return false;
        }
        return this.products.length === 0;
    }

    getEmptyContent() {
        return <MyEmpty />
    }

    isError() {
        return this.state.status === 'error';
    }

    getErrorContent() {
        return <Error />
    }

    getAllAldiCategoriesCallback(data) {
        this.categories = data;
        this.checkAllDataReady();
    }

    start() {
        console.log('start');
        this.categoryDao.findAldiCategoriesByCategoryId(this.categoryId, this.getAllAldiCategoriesCallback, this.onError);
        this.setState({ status: 'loading' });
    }
}

export default CatalogSubcategories;