import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import './NutritionInformation.css';

class NutritionInformation extends React.Component {
    counter = 0;

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
        this.getNutritionValue = this.getNutritionValue.bind(this);
    }

    getNutritionValue(id) {
        const { formatMessage } = this.props.intl;
        const str = formatMessage({id: id});
        const key = Object.keys(this.state.data._source).find(k => k.includes(str));

        return this.state.data._source[key];
    }

    render() {
        const { formatMessage } = this.props.intl;
        return <div id="nutritioninformation">
            <div id="nutritioninformation-title">
                <FormattedMessage id="nutritioninformation.title" />
            </div>
            <div id="nutritioninformation-data">
                <table>
                    <tbody>
                        <tr id='nutritioninformation-data' >
                            <td id='nutritioninformation-data-field'>{formatMessage({ id: 'nutritioninformation.energy.label' })}</td>
                            <td id='nutritioninformation-data-value'>{this.getNutritionValue('nutritioninformation.energy.value')}</td>
                        </tr>
                        <tr id='nutritioninformation-data' >
                            <td id='nutritioninformation-data-field'>{formatMessage({ id: 'nutritioninformation.carbohydrate.label' })}</td>
                            <td id='nutritioninformation-data-value'>{this.getNutritionValue('nutritioninformation.carbohydrate.value')}</td>
                        </tr>
                        <tr id='nutritioninformation-data' >
                            <td id='nutritioninformation-data-field'>{formatMessage({ id: 'nutritioninformation.sugars.label' })}</td>
                            <td id='nutritioninformation-data-value'>{this.getNutritionValue('nutritioninformation.sugars.value')}</td>
                        </tr>
                        <tr id='nutritioninformation-data' >
                            <td id='nutritioninformation-data-field'>{formatMessage({ id: 'nutritioninformation.protein.label' })}</td>
                            <td id='nutritioninformation-data-value'>{this.getNutritionValue('nutritioninformation.protein.value')}</td>
                        </tr>
                        <tr id='nutritioninformation-data' >
                            <td id='nutritioninformation-data-field'>{formatMessage({ id: 'nutritioninformation.fat.label' })}</td>
                            <td id='nutritioninformation-data-value'>{this.getNutritionValue('nutritioninformation.fat.value')}</td>
                        </tr>
                        <tr id='nutritioninformation-data' >
                            <td id='nutritioninformation-data-field'>{formatMessage({ id: 'nutritioninformation.satured.label' })}</td>
                            <td id='nutritioninformation-data-value'>{this.getNutritionValue('nutritioninformation.satured.value')}</td>
                        </tr>
                        <tr id='nutritioninformation-data' >
                            <td id='nutritioninformation-data-field'>{formatMessage({ id: 'nutritioninformation.fibre.label' })}</td>
                            <td id='nutritioninformation-data-value'>{this.getNutritionValue('nutritioninformation.fibre.value')}</td>
                        </tr>
                        <tr id='nutritioninformation-data' >
                            <td id='nutritioninformation-data-field'>{formatMessage({ id: 'nutritioninformation.salt.field.label' })}</td>
                            <td id='nutritioninformation-data-value'>{this.getNutritionValue('nutritioninformation.salt.field.value')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }
}

export default injectIntl(NutritionInformation);