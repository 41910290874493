import React, { Component } from 'react';
import './SubcategoryProducts.css';
import SubcategoryProductsBo from './SubcategoryProductsBo';
import Loading from './Loading';
import MyEmpty from './MyEmpty';
import Error from './Error';
import { Col, Row } from 'antd';
import ProductItem from './ProductItem';
import MyBreadcrumbs from './MyBreadcrumbs';
import ContentTitle from './ContentTitle';

class SubcategoryProducts extends Component {
    subcategoryProductsBo = new SubcategoryProductsBo();
    counter = 0;
    products = null;
    categories = [];
    categoryId;
    categoryName;
    subcategoryId;
    subcategoryName;

    constructor(props) {
        super(props);
        this.state = {};
        this.subcategoryId = this.props.match.params.subcategoryId;
        this.onError = this.onError.bind(this);
        this.getSubcategoryProductsCallback = this.getSubcategoryProductsCallback.bind(this);
        this.getCategoryNameCallback = this.getCategoryNameCallback.bind(this);
        this.getSubcategoryNameCallback = this.getSubcategoryNameCallback.bind(this);
    }

    getCategoryId(subcategoryId) {
        let result;
    
        if (subcategoryId < 100000) {
            result = subcategoryId / 1000;
        } else {
            result = subcategoryId / 10000;
        }
    
        return Math.floor(result);
    }

    hasTopCategory(subcategoryId) {
        return subcategoryId % 1000 !== 0;
    }

    getCategoryBaseCode() {
        if (this.categories[0]) {
            console.log('>>>> ' + JSON.stringify(this.categories[0]));
        }
        return undefined;
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.subcategoryId !== prevProps.match.params.subcategoryId) {
            this.subcategoryId = this.props.match.params.subcategoryId;
            this.start();
        }
    }

    render() {
        // console.log('status: ' + this.state.status);
        if (this.isLoading()) {
            return this.getLoadingContent();
        }
        if (this.isError()) {
            return this.getErrorContent();
        }
        if (this.isEmpty()) {
            return this.getEmptyContent();
        }
        if (this.isReady()) {
            return this.getReadyContent();
        }
        this.start();
        return this.getLoadingContent();
    }

    onError(errorResponse) {
        console.error(errorResponse);
        this.setState({ status: 'error' });
    }

    checkAllDataReady() {
        this.setState({ status: 'ready' });
    }

    isLoading() {
        return this.state.status === 'loading';
    }

    getLoadingContent() {
        return <Loading />
    }

    isReady() {
        return this.state.status === 'ready';
    }

    buildBreadcrumbs() {
        let breadcrumbs = [
            {label: 'mybreadcrumbs.start', url: '/'},
            {label: 'mybreadcrumbs.catalogmenu', url: '/catalogmenu'}
        ];

        if (this.hasTopCategory(this.subcategoryId)) {
            breadcrumbs.push(
                {label: this.categoryName, url: '/subcategory/' + this.categoryId}
            );
        };

        breadcrumbs.push({label: this.subcategoryName});

        return breadcrumbs;
    }

    getReadyContent() {
        const myBreadcrumbs = this.buildBreadcrumbs();

        return (
            <div>
                <MyBreadcrumbs data={myBreadcrumbs} />
                <ContentTitle id={this.subcategoryName} />
                <Row>
                    {this.categories.map(product => <Col key={this.counter++} xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <ProductItem item={product} productBaseUrl={'/productDetail/'} />
                    </Col>)}
                </Row>
            </div>
        )
    }

    isEmpty() {
        if (!this.products) {
            return false;
        }
        return this.products.length === 0;
    }

    getEmptyContent() {
        return <MyEmpty />
    }

    isError() {
        return this.state.status === 'error';
    }

    getErrorContent() {
        return <Error />
    }

    // TODO: Remove callback hell
    getSubcategoryProductsCallback(data) {
        this.categories = data;

        this.categoryId = this.getCategoryId(this.subcategoryId);
        this.subcategoryProductsBo.getCategory(this.categoryId * 1000
            , this.getCategoryNameCallback, this.onError);
    }

    getCategoryNameCallback(data) {
        this.categoryName = data._source.WGR_BEZEICHNUNG;

        this.subcategoryProductsBo.getCategory(this.subcategoryId
            , this.getSubcategoryNameCallback, this.onError);
    }

    getSubcategoryNameCallback(data) {
        this.subcategoryName = data._source.WGR_BEZEICHNUNG;
        this.checkAllDataReady();
    }

    start() {
        console.log('start');
        this.subcategoryProductsBo.getSubcategoryProducts(this.subcategoryId
            , this.getSubcategoryProductsCallback, this.onError);
        this.setState({ status: 'loading' });
    }
}

export default SubcategoryProducts;