import AccessTokenDao from './AccessTokenDao';

export default class RecommendationsDao {
    accessTokenDao = new AccessTokenDao();

    getRecommendations(userId) {
        const url = `${process.env.REACT_APP_DSS}/recommendations/${userId}`;
        const data = {
            headers: {
                'Accept': 'application/json',
                'authorization': this.accessTokenDao.get()
            }
        };
        return fetch(url, data)
            .then(response => response.json())
            .catch(errorResponse => {
                console.log(errorResponse);
            });
    }
}