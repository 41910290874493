import React from 'react';
import { Collapse } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import './OrderConsumptionsGuide.css';

export default function OrderConsumptionsGuide() {
    const { Panel } = Collapse;

    return (
        <Collapse
            className="order-consumptions-guide"
            expandIcon={() => <InfoCircleOutlined style={{fontSize: 'large', color:"#f50"}}/>}
        >
            <Panel header="Guía para introducir tus consumos">
                <ul>
                    <li>
                        CARNE (fresca o embutido) o PESCADO o HUEVOS, indica el consumo con <strong>UNIDADES</strong>.
                    </li>
                    <li>
                        FRUTAS o VERDURAS, indica el consumo con <strong>VECES</strong>.
                    </li>
                    <li>
                        AGUA o LECHE o CAFÉ otras BEBIDAS, indica el consumo con <strong>VASOS</strong>.
                    </li>
                    <li> 
                        FRUTOS SECOS, indica el consumo con <strong>PUÑADOS</strong>.
                    </li>
                    <li>
                        CEREALES (pasta, arroz, pan, cereales de desayuno…), indica el consumo con <strong>VECES</strong>.
                    </li>
                    <li>
                        YOGUR o POSTRES o similares, indica el consumo con <strong>UNIDADES</strong>.
                    </li>
                    <li>
                        QUESOS o MANTEQUILLA, ACEITE o similares, indica el consumo con <strong>VECES</strong>.
                    </li>
                    <li>
                        AZÚCAR o EDULCORANTES o MIEL u otros CONDIMENTOS, indica el consumo con <strong>CUCHARADA</strong>.
                    </li>
                    <li>
                        HARINA, indica el consumo en <strong>CUCHARADAS</strong>.
                    </li>
                    <li>
                        SNACKS (bombones, caramelos, chocolate, galletas, …), indica el consumo con <strong>UNIDADES</strong>.
                    </li>
                    <li>
                        CONSERVAS o PLATOS PREPARADOS, indica el consumo en <strong>VECES</strong>.
                    </li>
                </ul>
            </Panel>
        </Collapse>
    );
}
