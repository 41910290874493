import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './ProductItem.css'

export default class ProductItem extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        // this.onHandleAddToCart = this.onHandleAddToCart.bind(this);
    }

    // onHandleAddToCart = () => {
    //     this.props.onHandleAddToCart(this.props.item);
    // }

    render() {
        const marca = this.props.item._source.Marca;
        return <Link to={this.props.productBaseUrl + this.props.item._id}>
            <div id="product-container">
                <div id="product-border">
                    <div id="product-image-container">
                        <img id="product-image" alt={this.props.item._id} src={this.props.item._source.Imagen} />
                    </div>
                    <span id="product-text">
                        {this.props.item._source.PRODUCTO} {marca && marca !== "NULL" && `(${marca})`}
                    </span>
                </div>
            </div>
        </Link>
    }
}