import CategoryDao from "../dao/CategoryDao";

class CatalogMenuBo {
    categoryDao = new CategoryDao();

    getAllAldiCategories(callback, error) {
        this.categoryDao.findAllAldiCategories(callback, error);
    }

    filterSupercategories(categoryJson) {
        return categoryJson._source.WGR_UGR1_NR === '0'
            && categoryJson._source.WGR_UGR2_NR === '0'
            && categoryJson._source.WGR_UGR3_NR === '0';
    }
}

export default CatalogMenuBo;