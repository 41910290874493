import { Col, Row, Spin } from 'antd';
import React, { Component } from 'react';
import ProductDao from '../dao/ProductDao';
import ContentTitle from './ContentTitle';
import Error from './Error';
import MyBreadcrumbs from './MyBreadcrumbs';
import Empty from './MyEmpty';
import ProductItem from './ProductItem';

export default class SearchResults extends Component {
    productDao = new ProductDao();
    myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.searchresults',
        },
    ];

    constructor(props) {
        super(props);

        this.state = {products: []};
        this.getProductsCallback = this.getProductsCallback.bind(this);
        this.onError = this.onError.bind(this);
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        const searchTermVal = searchParams.get("searchTerm");
        this.setState({ status: 'loading'});
        this.productDao.findByProductName(searchTermVal, this.getProductsCallback, this.onError);
    }

    componentDidUpdate(prevProps) {
        const searchParams = new URLSearchParams(this.props.location.search);
        const searchTermVal = searchParams.get("searchTerm");

        const prevSearchParams = new URLSearchParams(prevProps.location.search);
        const prevSearchTermVal = prevSearchParams.get("searchTerm");
        if (searchTermVal !== prevSearchTermVal) {
            this.setState({ status: 'loading'});
            this.productDao.findByProductName(searchTermVal, this.getProductsCallback, this.onError);
        }
    }

    getProductsCallback(data) {
        this.setState({products: data, status: 'ready'});
    }

    onError(error) {
        console.log(error);
        this.setState({ status: 'error' });
    }

    isLoading() {
        return this.state.status === 'loading';
    }

    isError() {
        return this.state.status === 'error';
    }

    isEmpty() {
        return this.state.products.length === 0;
    }

    isReady() {
        return this.state.status === 'ready';
    }

    getLoadingContent() {
        return <Spin size="large" />
    }

    getErrorContent() {
        return <Error />
    }

    getEmptyContent() {
        return (
            <div>
                <MyBreadcrumbs data={this.myBreadcrumbs} />
                <ContentTitle id='searchresults.title' />
                <Row style={{ minHeight: '20em' }}>
                    <Empty />
                </Row>
            </div>
        );
    }

    getContent() {
        return (
            <div>
                <MyBreadcrumbs data={this.myBreadcrumbs} />
                <ContentTitle id="searchresults.title" />
                <Row>
                    {this.state.products.map(product => (
                        <Col key={product._id} xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                            <ProductItem key={product._id} item={product} productBaseUrl={'/productDetail/'} />
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }

    render() {
        if (this.isLoading()) {
            return this.getLoadingContent();
        }
        if (this.isError()) {
            return this.getErrorContent();
        }
        if (this.isEmpty()) {
            return this.getEmptyContent();
        }
        if (this.isReady()) {
            return this.getContent();
        }
    }
}