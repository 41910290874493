import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import RecommendationsDao from "../dao/RecommendationsDao";
import UserDataDao from "../dao/UserDataDao";
import ContentTitle from "./ContentTitle";
import './Faq.css';

export default function Faq() {
    const { Paragraph } = Typography;
    const intl = useIntl();
    const [userCluster, setUserCluster] = useState("");
    const [userGroup, setUserGroup] = useState("");

    useEffect(() => {
        const recommendationsDao = new RecommendationsDao();
        const userDataDao = new UserDataDao();
        const userId = userDataDao.getPreventomicsId();
        Promise.all([
            recommendationsDao.getRecommendations(userId),
            userDataDao.getDssUser(userId)
        ])
            .then(([recommendations, user]) => {
                setUserCluster(recommendations['contents']['Cluster'].toLowerCase());
                setUserGroup(user['group'].toLowerCase());
            });
    }, []);

    const generateFaq = (idPrefix) => {
        var val = 0;
        var faq = [];
        while (!!intl.messages[`${idPrefix}.question.${val}`]) {
            faq.push(
                <Row>
                    <Col offset={1} span={22}>
                        <Paragraph className="faq-question">
                            <FormattedMessage id={`${idPrefix}.question.${val}`} />
                        </Paragraph>
                        <Paragraph className="faq-answer">
                            <FormattedMessage id={`${idPrefix}.answer.${val}`} />
                        </Paragraph>
                    </Col>
                </Row>
            );
            val += 1;
        }

        return faq;
    }

    return (
        <div>
            <ContentTitle id="faq.title" />
            <Typography>
                {generateFaq("faq.allusers")}
                {(userGroup === "pp" || userGroup === "pn") && generateFaq("faq.pp.pn")}
                {userGroup === "pp" && generateFaq("faq.pp")}
                {userCluster === "control" && generateFaq("faq.control")}
            </Typography>
        </div>
    )
}