import React, { Component } from 'react';
import AccessTokenDao from '../dao/AccessTokenDao';
import UserDataDao from '../dao/UserDataDao';
import { Row, Col } from 'antd';
import ContentTitle from './ContentTitle';
import MyBreadcrumbs from './MyBreadcrumbs';

export default class Notifications extends Component {
    userDataDao = new UserDataDao();
    accessTokenDao = new AccessTokenDao();
    counter = 0;
    myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.notifications',
        },
    ];

    constructor(props) {
        console.log("notifications contructor");
        super(props);
        this.state = {
            messages: []
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(id, read) {
        const user_id = this.userDataDao.getAuth0Id();               
        const url = new URL(`${process.env.REACT_APP_URL}${process.env.REACT_APP_ALDI}/users/${user_id}/messages/${id}/`);

        // Update the message read/unread status in the backend
        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({read: read})
        })
        .then(() => {
            // Update the message read/unread status in the frontend
            this.setState((state) => {
                const messages = state.messages.map((item) => {
                    if (item._id === id) {
                        return {...item, read: read};
                    } else {
                        return item;
                    }
                    });
    
                this.props.setNotificationsCount(messages.filter(m => !m.read).length);
    
                return {messages};
            });    
        })
        .catch(error => {
            console.error(error);
        })
    }

    componentDidMount() {        
        this.userDataDao.getDssUser(this.userDataDao.getPreventomicsId())
        .then(user => {
            if (user.group.toLowerCase() === "pp") { //Personalized plan
                // Get the Behaviour change programme messages
                const user_id = this.userDataDao.getAuth0Id();
                let url = new URL(`${process.env.REACT_APP_URL}${process.env.REACT_APP_ALDI}/users/${user_id}/messages/`);
                url.search = new URLSearchParams({
                    query: JSON.stringify({
                        targetTime: {$lt: new Date().toISOString()}
                    }),
                    token: this.accessTokenDao.getAuth0Token()
                });
                fetch(url)
                .then(res => res.json())
                .then(messages => {
                    this.setState({messages: messages});
                    this.props.setNotificationsCount(messages.filter(m => !m.read).length);
                })
            }
        })
        .catch(error => {
            console.error(error);
        })
    }

    render() {
        return (
            <div style={{ minHeight: '20em' }}>
                <MyBreadcrumbs data={this.myBreadcrumbs} />
                <ContentTitle id='notifications.title' />
                <Row>
                    {
                        this.state.messages.map(n =>
                            <Col key={++this.counter} xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                <div
                                    style={{
                                        margin: '0.5em',
                                        padding: '1em',
                                        border: (n.read ? '1px' : '5px') + ' solid orange',
                                        borderRadius: '5px'
                                    }}
                                    onClick={() => this.handleClick(n._id, !n.read)}
                                >
                                    <div style={{
                                        margin: '0em 0em 1em 0em',
                                        padding: '0em 1em 0.5em 1em',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        border: '0px dotted orange',
                                        borderBottomWidth: '2px'
                                    }}>
                                        <span>{new Date(n.targetTime).toLocaleDateString()}</span>
                                    </div>
                                    <div style={{ fontWeight: 'bold', }}>{n.title}</div>
                                    <div>{n.body}</div>
                                </div>
                            </Col >
                        )
                    }
                </Row>
            </div>
        )
    }
}