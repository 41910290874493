import React, { Component } from 'react';
import UserDataDao from '../dao/UserDataDao';
import FormDao from '../dao/FormDao';
import { Col, Row, Avatar, Tag } from 'antd';
import { ExperimentOutlined, UserOutlined } from '@ant-design/icons';
import ContentTitle from './ContentTitle';
import Empty from './MyEmpty';
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import MyBreadcrumbs from './MyBreadcrumbs';
import Loading from './Loading';
import './Profile.css'

export default class Profile extends Component {
    userDataDao = new UserDataDao();
    formDao = new FormDao();
    profile_data = [
        {
            "literal": "profile.anthropometrics",
            "image": "anthropometrics.png",
            "url": "/anthropometrics?start=true",
        },
        {
            "literal": "profile.supplements",
            "icon": <ExperimentOutlined style={{ fontSize: '5em' }} />,
            "url": "/supplements",
        },
        {
            "literal": "profile.intake",
            "icon": <ExperimentOutlined style={{ fontSize: '5em' }} />,
            "url": "/intake",
        },
        {
            "literal": "profile.metabolomics",
            "image": "Metabolismo.png",
            "url": "/metabolomics?start=true",
        },
        {
            "literal": "profile.genetics",
            "image": "dna-2_orange.png",
            "url": "/genetics?start=true",
        }
    ];
    myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.myprofile',
        },
    ];

    constructor(props) {
        super(props);
        this.state = { status: '', restrictions: []};
        this.getFFQRestrictionsCallback = this.getFFQRestrictionsCallback.bind(this);
        this.onError = this.onError.bind(this);
    }

    componentDidMount() {
        const userId = this.userDataDao.getPreventomicsId();
        this.formDao.getFFQRestrictions(userId, this.getFFQRestrictionsCallback, this.onError);
    }

    getFFQRestrictionsCallback(data) {
        this.setState({ restrictions: data.contents , status: 'ready'});
    }

    onError(errorData) {
        console.log(errorData);
    }

    restrictionsMapFunction(restrictionKey) {
        if (this.state.restrictions[restrictionKey]?.value === true && restrictionKey !== "anyFoodAlergy") {
            return (
                <Tag key={restrictionKey} style={{fontSize: 'medium'}}>
                   <FormattedMessage id={restrictionKey} />
                </Tag>
            )
        }
    }

    getEmptyContent() {
        return <Row style={{ minHeight: '20em' }}>
            <Col span={24}>
                <Empty />
            </Col>
        </Row>
    }

    counter = 0;
    render() {
        if (this.state.status === 'ready') {
            var restrictions = Object.keys(this.state.restrictions)
                .map(k => this.restrictionsMapFunction(k))
                .filter(k => !!k);
         
            return (
                <div>
                    <MyBreadcrumbs data={this.myBreadcrumbs} />
                    <ContentTitle id="profile.title" />
                    <Row>
                        <Col sm={8} md={8} lg={8} xl={8} xxl={8} >
                            <div className='profileAvatar'>
                                <Avatar shape="square" size={128} icon={<UserOutlined />} />
                            </div>
                        </Col>
                        <Col sm={16} md={16} lg={16} xl={16} xxl={16} >
                            <div className='profileText'>
                                {restrictions.length > 0
                                    ? <>
                                          <div className="restrictionsText">
                                              <FormattedMessage id="profile.restrictionstext" />
                                          </div>
                                          <div>
                                              {restrictions}
                                          </div>
                                      </>
                                    : <FormattedMessage id="profile.norestrictions" />
                                }
                            </div>
                            <div className='profileText'>
                                <FormattedMessage id="profile.starttext" />
                            </div>
                            <Row>
                                {this.profile_data.map(f => <Col key={this.counter++} xs={8} sm={8} md={8} lg={4} xl={4} xxl={2}>
                                    <Link to={f.url} >
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '1em' }}>
                                            {
                                                f.icon
                                                ? f.icon
                                                : <Avatar size={70} src={f.image} /> 
                                            }
                                            <FormattedMessage id={f.literal} />
                                        </div>
                                    </Link>
                                    </Col>)
                                }
                            </Row>
                        </Col>
                    </Row>
                </div>
            )
        }
        return this.getLoadingContent();
    }

    getLoadingContent() {
        return <Loading />
    }
}