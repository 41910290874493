import React, { useState } from 'react';
import { FormattedMessage } from "react-intl"
import FlagDropdownMenu from './FlagDropdownMenu';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useAuth0 } from "../react-auth0-wrapper";
import messages_es from "../translations/es.json";
import messages_en from "../translations/en.json";
import { IntlProvider } from "react-intl";
import Logo from './Logo';
import './Login.css';

const Login = (props) => {
    const { loginWithRedirect } = useAuth0();

    const [locale, setLocale] = useState('es');

    const messages = {
        'es': messages_es,
        'en-GB': messages_en
    };

    const onLanguageSelect = (language) => {
        setLocale(language)
    }

    const loginWindow = <div style={{ borderRadius: '1em 1em', backgroundColor: 'white', borderStyle: 'solid', borderColor: 'gray', borderWidth: '1px', padding: '2em' }}>
        <div style={{ borderBottomWidth: 'thin', borderBottomStyle: 'solid', borderBottomColor: 'gray', backgroundColor: 'white', padding: '0.5em 1em 0.5em 0em', position: 'absolute', width: '100%', top: '0px', left: '0px', zIndex: '1', display: 'flex', flexDirection: 'horizontal', justifyContent: 'flex-end' }}>
            <FlagDropdownMenu onLanguageSelect={onLanguageSelect} currentLanguage={props.currentLanguage} />
        </div>
        <Logo />
        <div style={{ display: 'flex', flexDirection: 'horizontal', justifyContent: 'center', overflow: 'hidden', margin: '1em 0em 1em 0em' }}>
            <UserOutlined style={{ fontSize: '7em' }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'horizontal', justifyContent: 'center' }}>
            <Button className="login-form-button" onClick={() => loginWithRedirect()} size="large" style={{ marginTop: "1em", marginBottom: "1em" }} type="primary" >
                <FormattedMessage id="login.login" />
            </Button>
        </div>
    </div>;
    return <IntlProvider locale={locale} messages={messages[locale]}>
        <div style={{ overflow: 'scroll', display: 'flex', justifyContent: 'center', height: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {loginWindow}
            </div>
        </div>
    </IntlProvider>
}

export default Login;