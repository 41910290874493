import { Modal } from 'antd';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from "react-router-dom";
import UserDataDao from '../dao/UserDataDao';

export default function PendingConsumptionsWarning() {
    let history = useHistory();
    let intl = useIntl()

    /**
        Get all the shopping lists from 7 days ago or more with no consumptions
    */
    useEffect(() => {
        const userDataDao = new UserDataDao();
        const userId = userDataDao.getPreventomicsId();
        const urlGetOrders = `${process.env.REACT_APP_ALDI}/users/${userId}/lists/`;

        var date = new Date();
        date.setDate(date.getDate() - 7);
        const query = {
            date: {$lte: date.toISOString()},
            consumed: { $ne: true }
        }

        function onOkHandle() {
            history.push('/orders');
        }

        fetch(`${urlGetOrders}?query=${JSON.stringify(query)}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            })
            .then(orders => {
                if (orders.length > 0) {
                    Modal.warning({
                        title: intl.formatMessage({ id: "pending.consumptions.warning.title" }),
                        okText: intl.formatMessage({ id: "pending.consumptions.warning.ok" }),
                        onOk: onOkHandle
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [history, intl]);

    // This component is not rendered in the usual way.
    // It's rendered with the Modal.warning() function.
    // We had to implement it as component because we need the "intl"
    // object provided by the parent component.
    return null;
}
