import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { TagsOutlined } from '@ant-design/icons';
import './CategoryItem.css';

class CategoryItem extends Component {

    render() {
        return <Link to={'/subcategory/' + this.props.id}>
            <div id="category-container">
                <div id="category">
                    <TagsOutlined className="category-icon-size" type="tags" />
                    <span>{this.props.name}</span>
                </div>
            </div>
        </Link>
    }
}

export default CategoryItem;
