import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import ProductItem from './ProductItem';
import ContentTitle from './ContentTitle';
import Loading from './Loading';
import Empty from './MyEmpty';
import MyBreadcrumbs from './MyBreadcrumbs';
import RecommendationsDao from '../dao/RecommendationsDao';
import UserDataDao from '../dao/UserDataDao';

export default function RecommendedAldiProducts() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const {c_code, p_code} = useParams();
    const [userCluster, setUserCluster] = useState();
    const myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.recommendedproducts',
            url: '/recommendations'
        },
        {
            label: c_code,
            url: `/recommendations/${c_code}/foods`
        },
        {
            label: p_code
        }
    ];

    // Filter exceptional cases requested by the nutritionists
    function isProductRecommended(product) {
        if (p_code === "p_algae") {
            const prod_name = product['_source']['PRODUCTO'];
            switch (userCluster.toLowerCase()) {
                case "lipid":
                    if (prod_name === "Chlorella") return true;
                    break;
                case "inflammation":
                case "oxidativestress":
                    if (prod_name === "Espirulina") return true;
                    break;
                default:
                    if (prod_name !== "Chlorella" && prod_name !== "Espirulina") {
                        return true;
                    }
            };
            return false;
        };
        return true;
    };

    useEffect(() => {
        const recommendationsDao = new RecommendationsDao();
        const userDataDao = new UserDataDao();
        const urlGetProducts = `${process.env.REACT_APP_ELASTICSEARCH_FILTER}/api`;
        function getBodyAndHeaders() {
            return {
                method: 'post',
                body: JSON.stringify({
                    httpMethod: "get",
                    context: "/aldi-products-spanish-final-v1/product/_search",
                    queryString: `?q=p_code:${p_code}&size=${process.env.REACT_APP_ELASTICSEARCH_SIZE}`
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            };
        };
        Promise.all([
            fetch(urlGetProducts, getBodyAndHeaders()),
            recommendationsDao.getRecommendations(userDataDao.getPreventomicsId())
        ])
        .then(([res1, recommendations]) => Promise.all([res1.json(), recommendations]))
        .then(([products, recommendations]) => {
            setProducts(products.hits.hits);
            setUserCluster(recommendations['contents']['Cluster']);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
        });
    }, [p_code]);

    if (loading) {
        return <Loading />;
    }
    if (products.length === 0)
    {
        return <Empty />;
    }
    return (
        <div>
            <MyBreadcrumbs data={myBreadcrumbs} />
            <ContentTitle id="recommendedproducts.title" />
            <Row>
                {products.filter(isProductRecommended).map(product => (
                    <Col key={product._id} xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <ProductItem
                            key={product._id}
                            item={product}
                            productBaseUrl={`/recommendations/${c_code}/foods/${p_code}/products/`}/>
                    </Col>
                ))}
            </Row>
        </div>
    );
}