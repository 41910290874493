import UserDataDao from './UserDataDao';

export default class ProductListDao {
    user_auth0_id = new UserDataDao().getAuth0Id();
    base_url = `${process.env.REACT_APP_ALDI}/users/${this.user_auth0_id}/shopping_list/`;

    get() {
        return (
            fetch(this.base_url)
                .then(response => response.json())
                .catch(error => console.log(error))
        )
    }

    count() {
        return (
            fetch(`${this.base_url}count/`)
                .then(response => response.json())
                .catch(error => console.log(error))
        )
    }

    add(productId, quantity=1) {
        let init = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({product_id: productId, increment: quantity})
        }
        return (
            fetch(this.base_url, init)
                .then(response => response.json())
                .catch(error => console.log(error))
        )
    }

    remove(productId, quantity=1) {
        return this.add(productId, -quantity);
    }

    removeAll() {
        return fetch(this.base_url, {method: 'DELETE'})
    }
}