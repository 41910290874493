import React, { useState, useEffect } from 'react';
import UserDataDao from '../dao/UserDataDao';
import ContentTitle from './ContentTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prompt, useParams } from "react-router-dom";
import MyBreadcrumbs from './MyBreadcrumbs';
import Loading from './Loading';
import { List, Avatar, message, Descriptions, Divider, Col, Row, Button, Typography, Select } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import './Order.css';
import { useServingsClasses } from '../hooks/useServingsClasses';
import OrderConsumptionsGuide from './OrderConsumptionsGuide';


export default function Order() {
    const { Option } = Select;
    const { orderId } = useParams();
    const userDataDao = new UserDataDao();
    const userId = userDataDao.getPreventomicsId();
    const servingsClasses = useServingsClasses();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [myBreadcrumbs, setMyBreadcrumbs] = useState([]);
    const [pendingConsumptions, setPendingConsumptions] = useState(false);
    const intl = useIntl()

    function increase(i) {
        setProducts(products.map((item, j) => {
            if (j === i) {
                item.consumed += 1;
                return item;
            } else {
                return item;
            }
        }));
    };
    
    function decrease(i) {
        setProducts(products.map((item, j) => {
            if (j === i) {
                item.consumed -= 1;
                if (item.consumed < 0) {
                    item.consumed = 0;
                }
                return item;
            } else {
                return item;
            }
        }));
    };

    function setMeasure(i, value) {
        setProducts(products.map((item, j) => {
            if (j === i) {
                item.unit = value;
                return item;
            } else {
                return item;
            }
        }));
    }

    function getRecommendedServing(c_value) {
        var min = c_value['health_min_serving'];
        var max = c_value['health_max_serving'];
        var time = c_value['temporal_measure'];
        if (time === "day") {
            min = min * 7;
            max = max * 7;
        }
        return (
            <FormattedMessage
                id="order.recommended.servings"
                values={{min: min, max: max, time: 'week'}}
            />
        );
    };

    function totalConsumptionByServingsClass(c_value) {
        return products.filter(product => c_value['p_codes'].includes(product.p_code))
            .reduce((acc, cur) => acc + cur.consumed, 0)
    }

    function handleConfirmConsumptions() {
        const key = 'confirmingMessage';

        if (products.some(product => product.unit === undefined)) {
            message.error({content: 'Selecciona la medida de cada consumo', key});
            return;
        }

        const data = products.map(product =>
            ({
                _id: product._id,
                consumed: product.consumed,
                unit: product.unit
            })
        );

        setPendingConsumptions(false);
        message.loading({content: intl.formatMessage({id: 'order.updatingconsumptions'}), key, duration: 0});
        fetch(`${process.env.REACT_APP_ALDI}/users/${userId}/lists/${orderId}/consumptions/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (response.ok) {
                message.success({content: intl.formatMessage({id: 'order.updatedconsumptions'}), key});
            } else {
                message.error({content: 'Error', key})
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        const urlGetOrder = `${process.env.REACT_APP_ALDI}/users/${userId}/lists/${orderId}/`;
        fetch(urlGetOrder)
        .then(response => response.json())
        .then(orderItems => {
            setProducts(orderItems);
            setLoading(false);
            setMyBreadcrumbs([
                {
                    label: 'mybreadcrumbs.start',
                    url: '/',
                },
                {
                    label: 'mybreadcrumbs.orders',
                    url: '/orders',
                },
                {
                    label: intl.formatDate(orderItems[0].date).replace(/\//g, ".")
                }
            ])
        })
        .catch(error => {
            console.log(error);
        });
    }, [userId, orderId]);

    return (
        loading ?
        <Loading /> :
        <div>

            {/* If the consumptions are not confirmed prompt the user before navigating away from the page */}
            <Prompt
                when={pendingConsumptions}
                message={intl.formatMessage({id: "order.warningmessage"})}
            />

            <MyBreadcrumbs data={myBreadcrumbs} />
            <ContentTitle
                id="order.title"
                values={{date: intl.formatDate(products[0].date).replace(/\//g, ".")}}
            />
            <Typography className="orderconsumptions-text">
                <Typography.Paragraph>
                    <FormattedMessage id="orderconsumptions.text"></FormattedMessage>
                </Typography.Paragraph>
            </Typography>

            <OrderConsumptionsGuide />

            <p className="order-date">
                {intl.formatDate(products[0].date, {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })}
            </p>
            <List
                // Large devices (desktops, 992px and up)
                itemLayout = {window.screen.width < 992 ? "vertical": "horizontal"}
                dataSource={products}
                renderItem={(item, index) => (
                    <List.Item
                        className="order-item"
                        actions={[
                            <span className="action-quantity">
                                <FormattedMessage
                                    id="order.actionquantity"
                                    values={{quantity: item.quantity}}
                                />
                            </span>,
                            <div className="action-consumption">
                                <span>
                                    <FormattedMessage id="order.actionconsumption"/>
                                </span>
                                <span className="consumption">
                                    {item.consumed}
                                </span>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <PlusCircleOutlined
                                        className="plus"
                                        onClick={() => {setPendingConsumptions(true); increase(index)}}
                                    />
                                    <MinusCircleOutlined
                                        className="minus"
                                        onClick={() => {setPendingConsumptions(true); decrease(index)}}
                                    />
                                </div>
                                <Select
                                    style={{ width: 160 }}
                                    placeholder="Selecciona medida"
                                    value={item.unit}
                                    onSelect={value => setMeasure(index, value)}
                                >
                                    <Option value="plato">Plato</Option>
                                    <Option value="vaso">Vaso</Option>
                                    <Option value="cucharada">Cucharada</Option>
                                    <Option value="puñado">Puñado</Option>
                                    <Option value="unidad">Unidad</Option>
                                    <Option value="veces">Veces</Option>
                                </Select>
                            </div>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar
                                    src={item.Imagen}
                                    size={64}
                                />
                            }                        
                            title={
                                <span className="title">
                                  {item.PRODUCTO} {item.Marca && item.Marca !== "NULL" && item.Marca}
                                </span>
                            }
                        />
                    </List.Item>
                )}
            >
            </List>
            <Row className="order-footer">
                <Col>
                    <Button
                        className="order-confirmconsumptions"
                        onClick={handleConfirmConsumptions}
                        size="large"
                        type="primary"
                    >
                        <FormattedMessage id="order.confirmconsumptions" />
                    </Button>
                </Col>
            </Row>
            <Divider />
            <Row>
                {Object.entries(servingsClasses).map(([c_code, c_value]) => (
                    <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
                        <div className="servings-class-block">
                            <div className="image-and-desc">
                                <div className="image-container">
                                    <img
                                        alt={c_code.substring(2, c_code.length)}
                                        className="image"
                                        src={`${process.env.PUBLIC_URL}/${c_value['image']}`}
                                    />
                                </div>
                                <FormattedMessage id={c_code}></FormattedMessage>
                            </div>
                            <Descriptions column={1} bordered size="small">
                                <Descriptions.Item label={intl.formatMessage({id: "order.recommended"})}>
                                    {getRecommendedServing(c_value)}
                                </Descriptions.Item>
                                <Descriptions.Item label={intl.formatMessage({id: "order.consumed"})}>
                                    <span className={`consumed ${totalConsumptionByServingsClass(c_value)>0 ? "non-zero" : ""}`}>
                                        {totalConsumptionByServingsClass(c_value)}
                                    </span>
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                        
                    </Col>
                ))}
            </Row>
        </div>
    );
}
