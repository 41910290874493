import AccessTokenDao from './AccessTokenDao';

export default class FormDao {
    accessTokenDao = new AccessTokenDao();

    findByUserId(userId, callback, error) {
        console.log('TODO: fix the request');
        this.getAllUserData(userId, callback, error);
    }

    getAllUserData(userId, callback, error) {
        const url = process.env.REACT_APP_DSS + '/user/getbyid/' + userId;
        const data = {
            headers: {
                'authorization': this.accessTokenDao.get()
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                const json = responseJson;
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    getFFQRestrictions(userId, callback, error) {
        const url = process.env.REACT_APP_DSS + '/ffq/restrictions/' + userId;
        const data = {
            headers: {
                'authorization': this.accessTokenDao.get()
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                const json = responseJson;
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    getFFQSupplements(userId, callback, error) {
        const url = process.env.REACT_APP_DSS + '/ffq/supplements/' + userId;
        const data = {
            headers: {
                'authorization': this.accessTokenDao.get()
            }
        };
        fetch(url, data)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`${response.status} ${response.statusText}`);
                }
                return response.json();
            })
            .then((responseJson) => {
                const json = responseJson;
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    getFFQIntake(userId, callback, error) {
        const url = process.env.REACT_APP_DSS + '/ffq/intake/' + userId;
        const data = {
            headers: {
                'authorization': this.accessTokenDao.get()
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                const json = responseJson;
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    getAnthropometrics(userId, start, callback, error) {
        const url = process.env.REACT_APP_DSS + '/anthropometrics/' + userId + '?start=' + start;
        const data = {
            headers: {
                'authorization': this.accessTokenDao.get()
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                const json = responseJson;
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    getMetabolomics(userId, callback, error) {
        const url_metabolomics = process.env.REACT_APP_DSS + '/metabolomics/' + userId + '?start=true';
        const data = {
            headers: {
                'authorization': this.accessTokenDao.get()
            }
        };
        fetch(url_metabolomics, data)
            .then(response => response.json())
            .then(responseJson => {
                callback(responseJson);
            })
            .catch((errorResponse) => error(errorResponse));
    }
}