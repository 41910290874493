import React, { Component } from 'react';
import { Col, Descriptions, Row, Table, Typography } from 'antd';
import Loading from './Loading';
import ContentTitle from './ContentTitle';
import { FormattedMessage } from 'react-intl';
import UserDataDao from '../dao/UserDataDao';
import FormDao from '../dao/FormDao';
import Empty from './MyEmpty';
import MyBreadcrumbs from './MyBreadcrumbs';
import Error from './Error'

export default class Supplements extends Component {
    userDataDao = new UserDataDao();
    formDao = new FormDao();
    myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.myprofile',
            url: '/profile',
        },
        {
            label: 'mybreadcrumbs.supplements',
        },
    ];
    columns = [
        {
            title: <FormattedMessage id="forms.supplements.type"></FormattedMessage>,
            dataIndex: 'type',
            key: 'type',
            render: text => <Typography.Text style={{ fontSize: 'medium' }}>{text}</Typography.Text>
        },
        {
            title: <FormattedMessage id="forms.supplements.brand"></FormattedMessage>,
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: <FormattedMessage id="forms.supplements.name"></FormattedMessage>,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <FormattedMessage id="forms.supplements.strength"></FormattedMessage>,
            dataIndex: 'strength',
            key: 'strength',
        },
        {
            title: <FormattedMessage id="forms.supplements.amount"></FormattedMessage>,
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: <FormattedMessage id="forms.supplements.averagefreq"></FormattedMessage>,
            dataIndex: 'average_freq',
            key: 'average_freq',
        },
    ];
    datasource = [];

    constructor(props) {
        super(props);
        this.state = {};
        this.getFFQSupplementsCallback = this.getFFQSupplementsCallback.bind(this);
        this.onError = this.onError.bind(this);
    }

    getFFQSupplementsCallback(data) {
        // Remove empty supplements
        Object.keys(data.contents).forEach((key) =>
            (Object.keys(data.contents[key]).length === 0) && delete data.contents[key]);

        this.datasource = Object.keys(data.contents).map(f => ({
            key: f,
            type: <FormattedMessage id={f}></FormattedMessage>,
            ...data.contents[f],
            amount: <FormattedMessage id={this.getServingFrequency(data.contents[f]['amount'])}></FormattedMessage>,
            average_freq: <FormattedMessage id={this.getServingFrequency(data.contents[f]['average_freq'])}></FormattedMessage>
        }));
        this.setState({
            data: data
        });
    }

    onError(errorData) {
        console.log(errorData);
        this.setState({status: 'error'});
    }

    getEmptyContent() {
        return <Row style={{ minHeight: '20em' }}>
            <Col span={24}>
                <Empty />
            </Col>
        </Row>
    }

    getLoadingContent() {
        return <Loading />
    }

    getBgColor(index) {
        return index % 2 ? 'white' : "#fff6e6";
    }

    render() {
        console.log('data: ' + JSON.stringify(this.state.data));
        if (this.state.status === 'error') {
            return <Error />
        }
        if (this.state.data === undefined) {
            const userId = this.userDataDao.getPreventomicsId();
            this.formDao.getFFQSupplements(userId, this.getFFQSupplementsCallback, this.onError);
            return this.getLoadingContent();
        }

        return (
            <div>
                <MyBreadcrumbs data={this.myBreadcrumbs} />
                <ContentTitle
                    id={ this.state.data.description }
                    defaultMessage={ `${this.state.data.description} {version}` }
                    values={{ version: this.state.data.version ? 'v' + this.state.data.version : '' }}
                />
                <Row justify="center">
                    <Col xs={0} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Table
                            columns={this.columns}
                            dataSource={this.datasource}
                            pagination={{ hideOnSinglePage: true }}
                        />
                    </Col>
                    <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0} >
                        <Descriptions
                            bordered
                            column={{ xxl: 1, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                            size="small"
                        >
                            {this.datasource.map((row, index) =>
                                <React.Fragment key={row.type}>
                                    <Descriptions.Item style={{ backgroundColor: this.getBgColor(index)}} label={this.columns[0].title}>
                                        {row.type}
                                    </Descriptions.Item>
                                    <Descriptions.Item style={{ backgroundColor: this.getBgColor(index)}} label={this.columns[1].title}>
                                        {row.brand}
                                    </Descriptions.Item>
                                    <Descriptions.Item style={{ backgroundColor: this.getBgColor(index)}} label={this.columns[2].title}>
                                        {row.name}
                                    </Descriptions.Item>
                                    <Descriptions.Item style={{ backgroundColor: this.getBgColor(index)}} label={this.columns[3].title}>
                                        {row.strength}
                                    </Descriptions.Item>
                                    <Descriptions.Item style={{ backgroundColor: this.getBgColor(index)}} label={this.columns[4].title}>
                                        {row.amount}
                                    </Descriptions.Item>
                                    <Descriptions.Item style={{ backgroundColor: this.getBgColor(index)}} label={this.columns[5].title}>
                                        {row.average_freq}
                                    </Descriptions.Item>
                                    <br></br>
                                </React.Fragment>)
                            }
                        </Descriptions>
                    </Col>
                </Row>
            </div>
        )
    }

    getServingFrequency(value) {
        console.log(value);
        switch (value) {
            case "1":
                return "forms.servingfrequency.1";
            case "2":
                return "forms.servingfrequency.2";
            case "3":
                return "forms.servingfrequency.3";
            case "4":
                return "forms.servingfrequency.4";
            case "5":
                return "forms.servingfrequency.5";
            case "6":
                return "forms.servingfrequency.6";
            case "7":
                return "forms.servingfrequency.7";
            case "8":
                return "forms.servingfrequency.8";
            case "9":
                return "forms.servingfrequency.9";
            default:
                return "forms.servingfrequency.empty";
        }
    }
}