import React, { useState } from 'react';
import { Row, Col, Dropdown, Menu, Badge, Popover } from 'antd';
import { ShoppingCartOutlined, UserOutlined, SearchOutlined, QuestionOutlined } from '@ant-design/icons';
import BurgerMenu from './BurgerMenu';
import SearchInput from './SearchInput';
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Logo from './Logo';
import { useAuth0 } from '../react-auth0-wrapper';

const AppHeaderMobile = (props) => {
    const [visible, setVisible] = useState();
    let history = useHistory();
    
    const hide = () => {
        setVisible(false);
    };

    const handleVisibleChange = visible => {
        setVisible(visible);
    };

    const doLogout = () => {
        logout({
            returnTo: process.env.REACT_APP_URL
        });
    };

    const { logout } = useAuth0();

    const onMenuSelect = (event) => {
        if (['logout'].includes(event.key)) {
            doLogout();
        }
    };

    const dropdownMenu = (
        <Menu onClick={onMenuSelect}>
            <Menu.Item key="notifications">
                <Link to="/notifications" >
                    <FormattedMessage id="usermenu.notifications" />
                </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item>
                <Link to="/profile" >
                    <FormattedMessage id="usermenu.myprofile" />
                </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item>
                <Link to="/orders" >
                    <FormattedMessage id="usermenu.orders" />
                </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout">
                <FormattedMessage id="usermenu.logout" />
            </Menu.Item>
        </Menu>
    );

    return (
        <div>
            <Row align='middle'>
                <Col xs={3} sm={0} md={0} lg={0} xl={0} xxl={0} >
                    <BurgerMenu onLanguageSelect={props.onLanguageSelect} />
                </Col>
                <Col xs={9} sm={0} md={0} lg={0} xl={0} xxl={0}>
                    <Logo />
                </Col>
                <Col xs={3} sm={0} md={0} lg={0} xl={0} xxl={0}>
                    <div style={{ textAlign: 'center'}}>
                        <Popover
                            placement="bottom"
                            content={<SearchInput history={history} visible={visible} onClick={hide}/>}
                            trigger="click"
                            visible={visible}
                            onVisibleChange={handleVisibleChange}
                        >
                            <SearchOutlined style={{ fontSize: '24px'}} />
                        </Popover>
                    </div>
                </Col>
                <Col xs={3} sm={0} md={0} lg={0} xl={0} xxl={0}>
                    <Dropdown overlay={dropdownMenu} trigger={['click']}>
                        <div style={{ textAlign: 'center'}}>
                            <Badge count={props.notificationsCount} >
                                <UserOutlined style={{ fontSize: '24px'}} />
                            </Badge>
                        </div>
                    </Dropdown>
                </Col>
                <Col xs={3} sm={0} md={0} lg={0} xl={0} xxl={0}>
                    <div style={{ textAlign: 'center'}}>
                        <Link to={'/shoppingList'}>
                            <Badge count={ props.shoppingListCount }>
                                <ShoppingCartOutlined style={{ fontSize: '24px'}} />
                            </Badge>
                        </Link>
                    </div>
                </Col>
                <Col xs={3} sm={0} md={0} lg={0} xl={0} xxl={0}>
                    <div style={{ textAlign: 'center'}}>
                        <Link to={'/faq'}>
                            <QuestionOutlined style={{ fontSize: '24px'}} />
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AppHeaderMobile;