import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { slide as SlideMenu } from 'react-burger-menu';
import { Link } from "react-router-dom";
import './BurgerMenu.css';
import { Menu } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import CatalogMenuBo from './CatalogMenuBo';
import CatalogSubcategoriesBo from './CatalogSubcategoriesBo';
import { useServingsClasses } from '../hooks/useServingsClasses';
import { FormattedMessage } from 'react-intl';

const BurgerMenu = (props) => {
    const [categories, setCategories] = useState([]);
    const servingsClasses = useServingsClasses();
    let history = useHistory();
//    const [openSubmenus, setOpenSubmenus] = useState([]);

    const catalogMenuBo = new CatalogMenuBo();
    const catalogSubcategoriesBo = new CatalogSubcategoriesBo();

    // // submenu keys of first level
    // const rootSubmenuKeys = ['categories'];

    // const onOpenChange = openKeys => {
    //     const latestOpenKey = openKeys.find(key => openSubmenus.indexOf(key) === -1);
    //     console.log(latestOpenKey);
    //     console.log(openKeys);
    //     if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    //         if (latestOpenKey) {
    //             setOpenSubmenus([openKeys[0], latestOpenKey]);
    //         } else {
    //             setOpenSubmenus(openKeys);
    //         }
    //     } else {
    //         setOpenSubmenus(latestOpenKey ? [latestOpenKey] : []);
    //     }
    // };

    const changeToSpanish = () => {
        props.onLanguageSelect('es');
        hideMenu_bugFix();
    }

    const changeToEnglish = () => {
        props.onLanguageSelect('en-GB');
        hideMenu_bugFix();
    }

    const hideMenu_bugFix = () => {
        let element = document.getElementsByClassName("bm-overlay")[0];
        element.click();
    }

    useEffect(() => {
        const catalogMenuBo = new CatalogMenuBo();
        catalogMenuBo.getAllAldiCategories(getAllAldiCategoriesCallback, onError);
    }, []);

    const getAllAldiCategoriesCallback = (data) => {
        setCategories(data);
    };

    const onError = (errorResponse) => {
        console.error(errorResponse);
    };

    const hasSubcategories = category => {
        return category._source.BETTER + category._source.ALTAS + category._source.BAJAS == 0;
    };

    const gotoProducts = category => {
        history.push(`/subcategory-products/${category}`);
        hideMenu_bugFix();
    };

    return (
        <SlideMenu>
            <div id="burger-menu-title">ALDI Microsite</div>
            <Menu theme="dark" mode="inline" /*openKeys={openSubmenus} onOpenChange={onOpenChange}*/ inlineIndent={0}>
                <Menu.SubMenu key="recommendations" title={<FormattedMessage id="burgermenu.recommendedforyou" />}>
                    {Object.entries(servingsClasses).map(([c_code, c_value]) => (
                        <Menu.SubMenu
                            key={c_code}
                            title={
                                <span>
                                    <RightOutlined />
                                    <FormattedMessage id={c_code} />
                                </span>
                            }
                            style={{paddingLeft: 8}}
                        >
                            {c_value['p_codes'].map(p_code => (
                                <Menu.Item key={p_code}>
                                    <span style={{paddingLeft: 24}}>
                                        <FormattedMessage id={p_code} />
                                    </span>
                                    <Link onClick={hideMenu_bugFix} to={`/recommendations/${c_code}/foods/${p_code}/products`}/>
                                </Menu.Item>
                            ))}
                        </Menu.SubMenu>
                    ))}
                </Menu.SubMenu>
                <Menu.SubMenu key="categories" title={<FormattedMessage id="burgermenu.seeallsupermarket" />}>
                    {categories
                        .filter(c => catalogMenuBo.filterSupercategories(c))
                        .map(c => (
                            <Menu.SubMenu
                                key={c._source.WGR_NR}
                                title={
                                    <span>
                                        <RightOutlined />
                                        <span>{c._source.WGR_BEZEICHNUNG}</span>
                                    </span>
                                }
                                style={{paddingLeft: 8}}
                                // If there are no subcategories link to the products page of the category
                                {...(!hasSubcategories(c) && {onTitleClick: () => gotoProducts(c._source.Concatenate)})}
                            >
                                {categories
                                    .filter(sc => catalogSubcategoriesBo.filterSubcategories(sc, c._source.WGR_NR))
                                    .map(i => (
                                        <Menu.Item key={i._source.Concatenate}>
                                            <span style={{paddingLeft: 24}}>
                                                {i._source.WGR_BEZEICHNUNG}
                                            </span>
                                            <Link onClick={hideMenu_bugFix} to={ "/subcategory-products/" + i._source.Concatenate }/>
                                        </Menu.Item>
                                    ))}
                            </Menu.SubMenu>
                        ))}
                </Menu.SubMenu>
            </Menu >
            <div className="burger-menu-separator"></div>
            <div>Ajustes idioma:</div>
            <Link className="burgerMenuLink" key="es" onClick={changeToSpanish} to={''}>Castellano</Link>
            <Link className="burgerMenuLink" key="en" onClick={changeToEnglish} to={''}>Inglés</Link>
        </SlideMenu>
    );
}

export default BurgerMenu;