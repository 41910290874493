import React, { Component } from 'react';
import { Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';

export default class Bell extends Component {

    render() {
        return <Badge count={this.props.count}>
            <BellOutlined style={{ height: '100%', width: 'auto' }} />
        </Badge>
    }

}