import UserDataDao from './UserDataDao';

export default class ProductListDao {
    key = `product-list-${new UserDataDao().getAuth0Id()}`;

    get() {
        if (!localStorage.getItem(this.key)) {
            return {};
        }
        return JSON.parse(localStorage.getItem(this.key));
    }

    count() {
        let count = 0;
        const buffer = this.get();
        for (var key in buffer) {
            if (buffer[key] && buffer[key] !== null) {
                count += buffer[key];
            }
        }
        return count;
    }

    add(productId) {
        let buffer = this.get();
        // console.log(buffer);
        if (buffer[productId]) {
            buffer[productId] = buffer[productId] + 1;
        } else {
            buffer[productId] = 1;
        }
        localStorage.setItem(this.key, JSON.stringify(buffer));
    }

    exist(productId) {
        let buffer = this.get();
        if (buffer[productId] === undefined) {
            return false;
        }
        if (buffer[productId] === null) {
            return false;
        }
        if (buffer[productId] > 0) {
            return true;
        }
        return true;
    }

    remove(productId) {
        let buffer = this.get();
        // console.log(buffer);
        if (buffer[productId] >= 2) {
            buffer[productId] = buffer[productId] - 1;
        }
        else if (buffer[productId] < 2) {
            buffer[productId] = undefined;
        }
        localStorage.setItem(this.key, JSON.stringify(buffer));
    }

    removeAll(productId) {
        localStorage.setItem(this.key, JSON.stringify({}));
    }

    clean() {
        localStorage.removeItem(this.key);
    }
}