import React, { Component } from 'react';
import { Row, Col } from 'antd';
import LanguageSelector from './LanguageSelector';
import SessionMenu from './SessionMenu';
import Logo from './Logo';
import Subtitle from './Subtitle';
import './AppHeader.css';

class AppHeader extends Component {

    render() {
        return <Row id="appheader" className="top">
            <Col xs={0} sm={12} md={12} lg={12} xl={12} xxl={12} >
                <div className="left">
                    <Logo />
                </div>
                <div className="left">
                    <Subtitle />
                </div>
            </Col>
            <Col xs={0} sm={12} md={12} lg={12} xl={12} xxl={12} >
                <div className="right">
                    <LanguageSelector onLanguageSelect={this.props.onLanguageSelect} currentLanguage={this.props.currentLanguage} />
                </div>
                <div className="right">
                    <SessionMenu
                        handleLogout={this.handleLogout}
                        isAuthenticated='True'
                        shoppingListCount={this.props.shoppingListCount}
                        notificationsCount={this.props.notificationsCount}
                        history={this.props.history}
                    // onTabChange={this.onTabChange} 
                    />
                </div>
            </Col>
        </Row >
    }
}

export default AppHeader;