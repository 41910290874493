import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import './ContentTitle.css';

export default class ContentTitle extends Component {

    render() {
        console.log('key: ' + this.props.id);
        if (this.props.id) {
            return (
                <div id="content-title">
                    <FormattedMessage {...this.props} />
                </div>
            );
        }
        return (
            <div id="content-title">
                {this.props.text}
            </div>
        );
    }
}