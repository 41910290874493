import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { Alert } from 'antd';
import ContentTitle from './ContentTitle';
import Loading from './Loading';
import AccessTokenDao from '../dao/AccessTokenDao';
import UserDataDao from '../dao/UserDataDao';
import Empty from './MyEmpty';
import MyBreadcrumbs from './MyBreadcrumbs';
import './Genetics.css';

export default class Genetics extends Component {
    myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.myprofile',
            url: '/profile',
        },
        {
            label: 'mybreadcrumbs.genetics',
        },
    ];

    constructor(props) {
        super(props);
        this.state = {};
        this.cluster = null;
        this.warnings = [];
    }

    componentDidMount() {
        // Get the genetic warnings from the recommendations endpoint
        const userDataDao = new UserDataDao();
        const userId = userDataDao.getPreventomicsId();    
        const urlDSSRecommendations = `${process.env.REACT_APP_DSS}/recommendations/${userId}`;
        const token = {
            headers: {
                'authorization': new AccessTokenDao().get()
            }
        };

        fetch(urlDSSRecommendations, token)
        .then(res => res.json())
        .then(data => {
            this.cluster = data.contents.Cluster;
            this.warnings = data.contents.Food_recommendation.Warnings.SNPs_gen_pop_warnings;
            this.setState({status: 'ready'});
        })
        .catch(error => {
            console.log(error);
        });
    }

    render() {
        if (this.state.status === 'ready') {
            return (
                <div>
                    <MyBreadcrumbs data={this.myBreadcrumbs} />
                    <ContentTitle id="forms.genetics.title" />
                    {this.warnings.length === 0 || this.cluster.toLowerCase() === "control"
                        ? <Empty />
                        : this.warnings.map(warn =>
                            <Alert
                                className="genetics-alert"
                                message="Warning"
                                description={<FormattedMessage id={warn.trim()} />}
                                type="warning"
                                showIcon
                            />
                        )
                    }
                </div>
            );
        }
        return <Loading />;
    }
}