import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Avatar, List, Button } from "antd"
import { ShoppingCartOutlined } from '@ant-design/icons'
import { FormattedMessage, useIntl } from "react-intl"
import UserDataDao from "../dao/UserDataDao"
import ContentTitle from './ContentTitle';
import MyBreadcrumbs from './MyBreadcrumbs';
import './OrderList.css'

export default function OrderList() {
    const[ products, setProducts ] = useState([]);
    const intl = useIntl()
    const myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.orders',
        },
    ];

    useEffect(() => {
        const userDataDao = new UserDataDao();
        fetch(`${process.env.REACT_APP_ALDI}/users/${userDataDao.getPreventomicsId()}/lists/`)
            .then(response => response.json())
            .then(response => {
                setProducts(response);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <MyBreadcrumbs data={myBreadcrumbs} />
            <ContentTitle id='orderlist.title' />
            <List
                dataSource={products}
                renderItem={item => (
                    <List.Item
                        className={`orderlist-item ${!item.consumed?'order-not-consumed':''}`}
                        actions={[
                            // <Button className="details-button">
                            //     <FormattedMessage id="orderlist.detailsbutton" />
                            // </Button>,
                            <Button className="consumptions-button">
                                <Link to={`/orders/${item._id}`}>
                                    <FormattedMessage id="orderlist.consumptionsbutton" />
                                </Link>
                            </Button>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar
                                    className="avatar"
                                    icon={<ShoppingCartOutlined />}
                                />
                            }
                            title={
                                <span className="title">
                                    {intl.formatMessage(
                                        {id: 'orderlist.itemtitle'},
                                        {date: intl.formatDate(item.date).replace(/\//g, ".")}
                                    )}
                                </span>
                            }
                            description={
                                <p>
                                    {intl.formatDate(item.date, {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                </p>
                            }
                        />
                        <FormattedMessage
                            id="orderlist.itemcontent"
                            values={{numofproducts: item.num_of_list_items}}
                        />
                    </List.Item>
                )}
            >
            </List>
        </div>
    );
}
