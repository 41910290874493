import React from 'react';
import { Component } from 'react';
import { Breadcrumb } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import './MyBreadcrumbs.css';

class MyBreadcrumbs extends Component {

    render() {
        return <div id="mybreadcrumbs">
            <Breadcrumb >
                {this.props.data.map(b => b.url ?
                    <Breadcrumb.Item key={b.label}>
                        <Link to={b.url}>
                            <FormattedMessage id={b.label} />
                        </Link>
                    </Breadcrumb.Item>
                    : <Breadcrumb.Item key={b.label}>
                        <FormattedMessage id={b.label} />
                    </Breadcrumb.Item>
                )}
            </Breadcrumb>
        </div>
    }
}

export default MyBreadcrumbs;