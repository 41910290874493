import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import ProductDao from '../dao/ProductDao';
import { Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import './ProductsCarousel.css';

export default class ProductsCarousel extends React.Component {
    productDao = new ProductDao();
    counter = 0;
    onClickFunction;
    categoryProducts = [];

    constructor(props) {
        super(props);
        this.state = {};
        this.onClickFunction = props.onClick;
        this.findByPreventomicsCodeCallback = this.findByPreventomicsCodeCallback.bind(this);
        this.findByPreventomicsCodeError = this.findByPreventomicsCodeError.bind(this);
    }

    render() {
        // console.log('render!');
        if (this.isReady()) {
            // alert('ready!');
            // console.log(JSON.stringify(this.categoryProducts));
            return this.getContent();
        }
        this.productDao.findByPreventomicsCode(this.props.categoryId
            , this.findByPreventomicsCodeCallback, this.findByPreventomicsCodeError);
        return this.getLoadingContent();
    }

    getRandomProduct() {
        const randomNumber = Math.random();
        // console.log(this.categoryProducts.length);
        const randomIndexProduct = Math.floor(randomNumber * this.categoryProducts.length);
        // console.log('randomIndexProduct: ' + randomIndexProduct);
        let product = this.categoryProducts[randomIndexProduct];
        // console.log('random product: ' + JSON.stringify(product));
        return product;
    }

    getSimiliarProducts() {
        let products = [];
        for (let i = 0; i < this.props.howMany; i++) {
            let similarProduct = this.getRandomProduct();
            products.push(this.getProductContent(similarProduct));
        }
        return products;
    }

    getProductContent(product) {
        return <Link className='shadow' key={this.counter++} onClick={this.onClickFunction} to={this.props.productBaseUrl + product._id}>
            <div key={product._id} id="products-carousel-product-content">
                <img id="products-carousel-product-content" alt={product._source.PRODUCTO} src={product._source.Imagen} />
            </div >
        </Link>
    }

    getEmptyContent() {
        return <Row>
            <Col span={24}>
            </Col>
        </Row>
    }

    getContent() {
        if (this.categoryProducts.length === 0) {
            return this.getEmptyContent();
        }
        return <div id="products-carousel">
            <div id="products-carousel-title">
                <FormattedMessage id="relatedproducts.title" />
            </div>
            <div id="products-carousel-content">
                {this.getSimiliarProducts()}
            </div>
        </div>
    }

    isReady() {
        return this.state.status === 'ready';
    }

    findByPreventomicsCodeCallback(products) {
        this.categoryProducts = products;
        this.setState({ status: 'ready' });
    }

    findByPreventomicsCodeError(error) {
        console.log(error);
    }

    getLoadingContent() {
        return <Spin size="large" style={{ minHeight: '20em' }} />
    }
}