import React, { Component } from 'react';
import FormDao from '../dao/FormDao';
import UserDataDao from '../dao/UserDataDao';
import { Col, Row, Table, Typography } from 'antd';
import Loading from './Loading';
import ContentTitle from './ContentTitle';
import { FormattedMessage } from 'react-intl';
import Empty from './MyEmpty';
import MyBreadcrumbs from './MyBreadcrumbs';
import { List } from 'antd';
import './Intake.css'

export default class Intake extends Component {
    userDataDao = new UserDataDao();
    formDao = new FormDao();
    myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.myprofile',
            url: '/profile'
        },
        {
            label: 'mybreadcrumbs.intake',
        },
    ];
    columns = [
        {
            title: <FormattedMessage id="forms.intake.product"></FormattedMessage>,
            dataIndex: 'product',
            key: 'product',
            width: '70%',
            render: text => <Typography.Text style={{ fontSize: 'medium' }}>{text}</Typography.Text>
        },
        {
            title: <FormattedMessage id="forms.intake.serving"></FormattedMessage>,
            dataIndex: 'value',
            key: 'value',
            width: '30%',
            render: text => <Typography.Text style={{ fontSize: 'medium' }}>{text}</Typography.Text>
        },
    ];
    source;
    datasource = [];

    constructor(props) {
        super(props);
        this.state = {};
        this.getFFQIntakeCallback = this.getFFQIntakeCallback.bind(this);
        this.onError = this.onError.bind(this);
    }

    getFFQIntakeCallback(data) {
        this.source = data;
        this.datasource = Object.keys(data.contents).map(f => ({
            product: <FormattedMessage id={f}></FormattedMessage>,
            value: <FormattedMessage id={this.getServingFrequency(data.contents[f])}></FormattedMessage>,
        }));
        this.setState({ status: 'ready' });
    }

    onError(errorData) {
        console.log(errorData);
    }

    getEmptyContent() {
        return <Row>
            <Col span={24}>
                <Empty />
            </Col>
        </Row>
    }

    render() {
        // console.log('data: ' + JSON.stringify(this.state.data));
        if (this.state.status === 'ready') {
            if (this.datasource.length === 0) {
                return this.getEmptyContent();
            }
            return (
                <div>
                    <MyBreadcrumbs data={this.myBreadcrumbs} />
                    <ContentTitle
                        id={ this.source.description }
                        defaultMessage={ `${this.source.description} {version}` }
                        values={{ version: this.source.version ? 'v' + this.source.version : '' }}
                    />
                    <Row justify="center">
                        <Col xs={0} sm={16} md={16} lg={16} xl={16} xxl={16}>
                            <Table
                                columns={this.columns}
                                dataSource={this.datasource}
                                pagination={{
                                    position: ['topRight', 'bottomRight'],
                                    hideOnSinglePage: true,
                                    pageSize: 8,
                                    simple: true
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                            <List
                                itemLayout="horizontal"
                                dataSource={this.datasource}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={<div class="itemProduct">{item.product}</div>}
                                            description={<div class="itemServing">{item.value}</div>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </div>
            )
        }
        const userId = this.userDataDao.getPreventomicsId();
        this.formDao.getFFQIntake(userId, this.getFFQIntakeCallback, this.onError);
        return this.getLoadingContent();
    }

    getLoadingContent() {
        return <Loading />
    }

    getServingFrequency(value) {
        switch (value) {
            case 1:
                return "forms.servingfrequency.1";
            case 2:
                return "forms.servingfrequency.2";
            case 3:
                return "forms.servingfrequency.3";
            case 4:
                return "forms.servingfrequency.4";
            case 5:
                return "forms.servingfrequency.5";
            case 6:
                return "forms.servingfrequency.6";
            case 7:
                return "forms.servingfrequency.7";
            case 8:
                return "forms.servingfrequency.8";
            case 9:
                return "forms.servingfrequency.9";
            default:
                return "forms.servingfrequency.empty";
        }
    }
}