import React, { Component } from 'react';
import { Spin } from 'antd';
import './Loading.css';

export default class Loading extends Component {

    render() {
        return <div id="loading">
            <Spin size="large" />
        </div>
    }
}

