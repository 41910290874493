import React from 'react';
import { Row, Col, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'
import ContentTitle from './ContentTitle';
import Loading from './Loading';
import MyBreadcrumbs from './MyBreadcrumbs';
import { useServingsClasses } from '../hooks/useServingsClasses';
import './RecommendedFoods.css';

export default function RecommendedFoods() {
    const servingsClasses = useServingsClasses();
    const { c_code } = useParams();
    const myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.recommendedproducts',
            url: '/recommendations'
        },
        {
            label: c_code
        }
    ];

    function text_columns() {
        if (servingsClasses[c_code].nutritional_info.image) {
            return {xs:24, sm:24, md:24, lg:12, xl:16, xxl:16}
        } else {
            return {}
        }
    }

    if (Object.keys(servingsClasses).length === 0) {
        return <Loading />;
    }
    return (
        <div>
            <MyBreadcrumbs data={myBreadcrumbs} />
            <ContentTitle id={c_code} />
            <Row>
                <Col {...text_columns()}>
                    {servingsClasses[c_code].nutritional_info.textid &&
                        <Typography className="recommended-foods-text">
                            <Typography.Paragraph>
                                <FormattedMessage id={servingsClasses[c_code].nutritional_info.textid}></FormattedMessage>
                            </Typography.Paragraph>
                            
                            <Typography.Paragraph>
                                <FormattedMessage
                                    id={servingsClasses[c_code].cluster_recommendations_textid}
                                    defaultMessage=" "
                                />
                            </Typography.Paragraph>
                        </Typography>
                    }
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                    {servingsClasses[c_code].nutritional_info.image &&
                        <img
                            className="plate-image"
                            alt="Healthy eating plate"
                            src={`${process.env.PUBLIC_URL}/${servingsClasses[c_code].nutritional_info.image}`}
                        />
                    }
                </Col>
            </Row>
            <Row>
                {servingsClasses[c_code].p_codes.map(p_code => (
                    <Col key={p_code} xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <Link to={`/recommendations/${c_code}/foods/${p_code}/products`}>
                            <div className="food-container">
                                <div className="block">
                                    <img
                                        className="image"
                                        alt={c_code.substring(2, c_code.length)}
                                        src={`${process.env.PUBLIC_URL}/${servingsClasses[c_code].icon}`}
                                    />
                                    <span className="text" >
                                        <FormattedMessage id={p_code} />
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
}