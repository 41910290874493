import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export default class PrintableList extends React.Component {
  render() {
    return <table>
      <tbody>
        <tr>
          <td span="2">
            <strong>
                <FormattedMessage id="printablelist.title" />
                <FormattedDate value={new Date()} />
            </strong>
          </td>
        </tr>
        <tr>
          <td>
            <br />
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage id="printablelist.text" />
          </td>
        </tr>
        <tr>
          <td>
            <br />
          </td>
        </tr>
        {this.props.products.filter(product => this.filter(product))
          .map(product => <tr key={product._id}>
            <td>+ {this.props.howMany[product._id]} x {product._source.PRODUCTO} ({product._source.Marca})</td>
          </tr>)}
      </tbody>
    </table>
  }

  filter(product) {
    return this.props.howMany[product._id] && this.props.howMany[product._id] !== null;
  }
}