import React, { Component } from 'react';
import FlagDropdownMenu from './FlagDropdownMenu';
import './LanguageSelector.css';

export default class LanguageSelector extends Component {

    onLanguageSelect = (event) => {
        this.props.onLanguageSelect(event);
    }

    render() {
        // console.log('LanguageSelector: default language: ' + this.props.currentLanguage);
        return <div id="languageSelector">
            <FlagDropdownMenu onLanguageSelect={this.onLanguageSelect} currentLanguage={this.props.currentLanguage} />
        </div>
    }
}