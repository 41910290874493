import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Table, Typography, List, Tag } from 'antd';
import Loading from './Loading';
import ContentTitle from './ContentTitle';
import AccessTokenDao from '../dao/AccessTokenDao';
import UserDataDao from '../dao/UserDataDao';
import FormDao from '../dao/FormDao';
import Empty from './MyEmpty';
import MyBreadcrumbs from './MyBreadcrumbs';
import './Metabolomics.css'
import HSBar from "./HorizontalStackedBar";

export default class Metabolomics extends Component {
    userDataDao = new UserDataDao();
    formDao = new FormDao();
    metabolomics_metadata = [
        {
            label: "b_glucose", 
            limits: [
                {label: <FormattedMessage id="forms.metabolomics.optimal" />, value: 10, description: "< 5.5", fontColor: "white", color: "green"},
                {label: <FormattedMessage id="forms.metabolomics.borderline" />, value: 10, description: ">5.6 - 6.9", fontColor: "black", color: "yellow"},
                {label: <FormattedMessage id="forms.metabolomics.high" />, value: 10, description: "> 7", fontColor: "white", color: "red"}
            ],
        },
        {
            label: "b_tag",
            limits: [
                {label: <FormattedMessage id="forms.metabolomics.optimal" />, value:10, description: "<1.7", fontColor: "white", color: "green"},
                {label: <FormattedMessage id="forms.metabolomics.borderline.high" />, value:10, description: "1.7 - 2.25", fontColor: "black", color: "yellow"},
                {label: <FormattedMessage id="forms.metabolomics.high" />, value:10, description: "2.26 - 5.64", fontColor: "white", color: "red"},
                {label: <FormattedMessage id="forms.metabolomics.very.high" />, value:10, description: ">5.65", fontColor: "white", color: "darkred"}
            ],
        },
        {
            label: "b_chol",
            limits: [
                {label: <FormattedMessage id="forms.metabolomics.optimal" />, value:10, description: "<5.17", fontColor: "white", color: "green"},
                {label: <FormattedMessage id="forms.metabolomics.borderline" />, value:10, description: "5.17 - 6.20", fontColor: "black", color: "yellow"},
                {label: <FormattedMessage id="forms.metabolomics.high" />, value:10, description: ">6.21", fontColor: "white", color: "red"}
            ]
        },
        {
            label: "b_hdl",
            limits: [
                {label: <FormattedMessage id="forms.metabolomics.low" />, value:10, description: "<1.03", fontColor: "white", color: "red"},
                {label: <FormattedMessage id="forms.metabolomics.optimal" />, value:10, description: ">1.04", fontColor: "white", color: "green"}
            ]
        },
        {
            label: "b_ldl",
            limits: [
                {label: <FormattedMessage id="forms.metabolomics.optimal" />, value:10, description: "<2.59", fontColor: "white", color: "green"},
                {label: <FormattedMessage id="forms.metabolomics.near.optimal" />, value:15, description: "2.59 - 3.35", fontColor: "black", color: "lightgreen"},
                {label: <FormattedMessage id="forms.metabolomics.borderline.high" />, value:10, description: "3.36 - 4.13", fontColor: "black", color: "yellow"},
                {label: <FormattedMessage id="forms.metabolomics.high" />, value:10, description: "4.14 - 4.90", fontColor: "white", color: "red"},
                {label: <FormattedMessage id="forms.metabolomics.very.high" />, value:10, description: ">4.90", fontColor: "white", color: "darkred"}
            ]
        }
    ];
    clusters = {
        'Carbohydrate': "carbohydrate",
        'Carbohydrates': "carbohydrate",
        'Lipid': "lipid",
        'Inflammation': "inflammation",
        'OxidativeStress': "oxidativestress",
        'Microbiota': "microbiota"
    };
    myBreadcrumbs = [
        {
            label: 'mybreadcrumbs.start',
            url: '/',
        },
        {
            label: 'mybreadcrumbs.myprofile',
            url: '/profile',
        },
        {
            label: 'mybreadcrumbs.metabolomics',
        },
    ];
    source;
    datasource;
    columns = [
        {
            title: <FormattedMessage id="forms.metabolomics.label"></FormattedMessage>,
            dataIndex: 'label',
            key: 'label',
            width: '5em',
            render: text => (
                <Typography.Text style={{ fontSize: 'medium' }}>
                    <FormattedMessage id={text} />
                </Typography.Text>
            )
        },
        {
            title: <FormattedMessage id="forms.metabolomics.value"></FormattedMessage>,
            dataIndex: 'value',
            key: 'value',
            width: '5em',
            render: text => (
                <Typography.Text style={{ fontFamily: 'monospace', fontSize: 'large' }}>
                    {text} <b>mmol/L</b>
                </Typography.Text>
            )
        },
        {
            dataIndex: 'limits',
            key: 'limits',
            align: 'right',
            width: '16em',
            render: text => (
                <HSBar
                    showTextIn
                    showTextUp
                    height={30}
                    data={text}
                />)
        },
    ];

    constructor(props) {
        super(props);
        this.state = {};
        this.getMetabolomicsCallback = this.getMetabolomicsCallback.bind(this);
        this.onError = this.onError.bind(this);
    }

    getMetabolomicsCallback(metabolomics) {
        this.source = metabolomics;
        // Show to the user only the familiar biomarkers
        this.datasource = this.metabolomics_metadata.map(b => ({
            label: b.label,
            value: this.source?.contents?.[b.label],
            limits: b.limits
        }));

        // Get the cluster from the recommendations endpoint
        const userDataDao = new UserDataDao();
        const userId = userDataDao.getPreventomicsId();    
        const accessTokenDao = new AccessTokenDao();
        const urlDSSRecommendations = `${process.env.REACT_APP_DSS}/recommendations/${userId}`;

        fetch(urlDSSRecommendations, {
            headers: {
                'authorization': accessTokenDao.get()
            }
        })
        .then(response => response.json())
        .then(data => {
            this.cluster = data.contents.Cluster;
            this.setState({ status: 'ready' });
        })
        .catch(error => {
            console.log(error);
        });
    }

    onError(errorData) {
        console.log(errorData);
    }

    getEmptyContent() {
        return <Row style={{ minHeight: '20em' }}>
            <Col span={24}>
                <Empty />
            </Col>
        </Row>
    }

    render() {
        if (this.state.status === 'ready') {
            if (this.datasource.length === 0) {
                return this.getEmptyContent();
            }
            return (
                <div>
                    <MyBreadcrumbs data={this.myBreadcrumbs} />
                    <ContentTitle
                        id={ this.source.description ?? "forms.metabolomics.title" }
                        defaultMessage={ `${this.source.description} {version}` }
                        values={{ version: this.source.version ? 'v' + this.source.version : '' }}
                    />
                    <Typography className="metabolomics-text">
                        <Typography.Paragraph>
                            <FormattedMessage id="forms.metabolomics.text"></FormattedMessage>
                        </Typography.Paragraph>
                    </Typography>
                    <Row justify="center">
                        <Col xs={0} sm={16} md={16} lg={16} xl={16} xxl={16}>
                            <Table
                                columns={this.columns}
                                dataSource={this.datasource}
                                pagination={{
                                    hideOnSinglePage: true,
                                    pageSize: 8,
                                    simple: true
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                            <List
                                itemLayout="horizontal"
                                dataSource={this.datasource}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={
                                                <div className="itemTitle">
                                                    <Row justify="space-between">
                                                        <Col>
                                                            <FormattedMessage id={item.label} />
                                                        </Col>
                                                        <Col>
                                                           {item.value} mmol/L
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                            description={
                                                <div className="itemDescription">
                                                    <HSBar showTextIn showTextUp height={30} data={item.limits} />
                                                </div>
                                            }
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                    {this.cluster.toLowerCase() !== "control" && (
                        <div>
                            <ContentTitle
                                id="cluster.title"
                                tagName="span"
                                values={{
                                    tag: msg => <Tag color="#f50" style={{fontSize: '18px'}}>{msg}</Tag>,
                                    cluster: this.cluster
                                }}
                            />
                            <Typography className="cluster-text">
                                <Typography.Paragraph>
                                    <FormattedMessage id={`info.${this.clusters[this.cluster]}`} />
                                </Typography.Paragraph>
                            </Typography>
                        </div>
                    )}
                </div>
            )
        }
        const userId = this.userDataDao.getPreventomicsId();
        this.formDao.getMetabolomics(userId, this.getMetabolomicsCallback, this.onError);
        return this.getLoadingContent();
    }

    getLoadingContent() {
        return <Loading />
    }
}