import React, { useState, useEffect } from 'react';
import { IntlProvider } from "react-intl";
import { notification, Spin } from 'antd';
import ContentLayout from '../components/ContentLayout';
import Login from '../components/Login';
import PushSubscriptionDialog from '../components/PushSubscriptionDialog';
import PendingConsumptionsWarning from '../components/PendingConsumptionsWarning';
import { useAuth0 } from "../react-auth0-wrapper";
import './App.css';
import messages_es from "../translations/es.json";
import messages_en from "../translations/en.json";

const App = () => {

  const translations = {
    'es': messages_es,
    'en-GB': messages_en
  };

  const { loading, isAuthenticated } = useAuth0();
  const [locale, setLocale] = useState('es');
  const [messages, setMessages] = useState(translations);

  // Create a global dictionary for the React IntlProvider
  // using the messages of the translation files and the messages
  // from the /i18 DSS API endpoint
  useEffect(() => {
    Promise.all([
      fetch(`${process.env.REACT_APP_DSS}/i18n/es`),
      fetch(`${process.env.REACT_APP_DSS}/i18n/en`)
    ])
    .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
    .then(([es, en]) => {
      setMessages({
        'es': {
          ...messages_es,
          ...es['preventomics_foods'],
          ...es['warnings']
        },
        'en-GB': {
          ...messages_en,
          ...en['preventomics_foods'],
          ...en['warnings']
        }
      });
    })
    .catch(error => {
      console.log(error);
    });
  }, []);

  notification.config({
    placement: 'topRight',
    top: 70,
    duration: 3,
  });

  const onLanguageSelect = (language) => {
    setLocale(language)
  }

  const getContent = (isAutenticated) => {
    if (isAuthenticated) {
      return (
        <div>
          <PendingConsumptionsWarning />
          <PushSubscriptionDialog />
          <ContentLayout currentLanguage={locale} onLanguageSelect={onLanguageSelect} />
        </div>
      )
    }
    return <div className="page-bg">
      <Login />
    </div>
  }

  if (loading) {
    return <Spin size="large" />
  }
  return <IntlProvider locale={locale} messages={messages[locale]}>
    {getContent(isAuthenticated)}
  </IntlProvider>
}

export default App;