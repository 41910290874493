import React, { Component } from 'react';
import { FrownOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import './Error.css';

class Error extends Component {

    render() {
        return <div id="error">
            <FrownOutlined className="icon-size" />
            <FormattedMessage id="error.message" />
        </div>
    }
}

export default Error;