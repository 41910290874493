import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { Empty } from 'antd';
import './MyEmpty.css';

export default class MyEmpty extends Component {
    render() {
        return <div id="empty">
            <Empty size="large" description={
                <FormattedMessage id="empty.nodata" />
            } />
        </div>
    }
}