import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { injectIntl } from 'react-intl';
import './ComercialInformation.css';
import Instructions from './Instructions';

class ComercialInformation extends Component {
    counter = 0;

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }

    render() {
        const { formatMessage } = this.props.intl;
        const marca = this.state.data._source[formatMessage({ id: 'comercialinformation.brand.field.value' })];
        return <Row>
            <Col span={24} >
                <div id='comercialinformation-title'>
                    {this.state.data._source[formatMessage({ id: 'comercialinformation.product.field.value' })]}&nbsp;
                    {marca && marca !== "NULL" && `${marca} `}
                    {this.state.data._source[formatMessage({ id: 'comercialinformation.weight.field.value' })]}
                </div>
            </Col>
            <Col span={24} >
                <div className='comercialinformation-centered'>
                    <div id='comercialinformation-image-container'>
                        <img alt='banner' id='comercialinformation-image' src={this.state.data._source[formatMessage({ id: 'comercialinformation.image.field.value' })]} />
                    </div>
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                <div className='comercialinformation-centered'>
                    <Instructions key={this.counter++} field={formatMessage({ id: 'comercialinformation.instructions.field.label' })} data={this.state.data._source[formatMessage({ id: 'comercialinformation.instructions.field.value' })]} />
                </div>
            </Col>
        </Row>
    }
}

export default injectIntl(ComercialInformation);