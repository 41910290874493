import React, { Component } from 'react';
import AppFooter from './AppFooter';
import { Route, Switch } from "react-router-dom";
import ProductList from './ProductList';
import ShoppingList from './ShoppingList';
import ProductDetail from './ProductDetail';
import Notifications from './Notifications'
import RecommendedClasses from './RecommendedClasses'
import RecommendedFoods from './RecommendedFoods';
import RecommendedAldiProducts from './RecommendedAldiProducts';
import Genetics from './Genetics';
import Supplements from './Supplements';
import Intake from './Intake';
import Anthropometrics from './Anthropometrics';
import Metabolomics from './Metabolomics';
import AppHeader from './AppHeader';
import AppMenu from './AppMenu';
import CatalogMenu from './CatalogMenu';
import CatalogSubcategories from './CatalogSubcategories';
import SubcategoryProducts from './SubcategoryProducts';
import ShoppingListDao from '../dao/ShoppingListDao_v2';
import "./ContentLayout.css";
import { Col } from 'antd';
import SearchResults from './SearchResults';
import Profile from './Profile';
import AppHeaderMobile from './AppHeaderMobile';
import UserDataDao from '../dao/UserDataDao';
import AccessTokenDao from '../dao/AccessTokenDao';
import OrderList from './OrderList';
import Order from './Order';
import Dashboard from './Dashboard';
import Faq from './Faq';

class ContentLayout extends Component {
    shoppingListDao = new ShoppingListDao();
    userDataDao = new UserDataDao();
    accessTokenDao = new AccessTokenDao();

    constructor(props) {
        super(props);
        this.state = {
            route: this.props.route,
            productList: [],
            shoppingListCount: 0,
            notificationsCount: 0
        };
        this.onTabChange = this.onTabChange.bind(this);
        this.onLanguageSelect = this.onLanguageSelect.bind(this);
        this.onHandleAddToCart = this.onHandleAddToCart.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.setShoppingListCount = this.setShoppingListCount.bind(this);
        this.setNotificationsCount = this.setNotificationsCount.bind(this);
    }

    componentDidMount() {
        this.userDataDao.getDssUser(this.userDataDao.getPreventomicsId())
        .then(user => {
            if (user.group.toLowerCase() === "pp") { //Personalized plan
                // Get the number of unread Behaviour change programme messages
                const user_id = this.userDataDao.getAuth0Id();
                let url = new URL(`${process.env.REACT_APP_URL}${process.env.REACT_APP_ALDI}/users/${user_id}/messages/count/`);
                url.search = new URLSearchParams({
                    query: JSON.stringify({
                        targetTime: {$lt: new Date().toISOString()},
                        read: {$ne: true}
                    }),
                    token: this.accessTokenDao.getAuth0Token()
                });
                fetch(url)
                .then(res => res.json())
                .then(count => {
                    this.setNotificationsCount(count);
                })
            }
        })
        .catch(error => {
            console.error(error);
        });

        // Set the the total quantity of products in the current shopping list
        this.shoppingListDao.count()
            .then(count => this.setShoppingListCount({value: count}));
    }

    onHandleAddToCart = (product) => {
        this.setState({ productList: [...this.state.productList, product] });
    }

    onHandleRemoveFromCart = (productId) => {
        this.setState({ productList: this.state.productList.filter(e => e.id !== productId) });
    }

    onHandleCleanCart = () => {
        this.setState({ productList: [] });
    }

    onTabChange(tabName) {
        this.setState({ route: tabName });
    }

    onLanguageSelect(language) {
        this.props.onLanguageSelect(language);
    }

    handleLogout() {
        console.log('logout2!');
        this.props.handleLogout("/login", "success", "You're successfully logged out.");
    }

    // TODO: Use Redux to manage the state of the shopping list.
    // It's ugly to have the state in this component but it's the only way as it's the common ancestor of
    // ProductDetail, ShoppingList and ShoppingCartIcon
    setShoppingListCount({value, increment=false} = {}) {
        this.setState(state => {
            return {shoppingListCount: (increment ? state.shoppingListCount : 0) + value};
        });
    }

    setNotificationsCount(notificationsCount) {
        this.setState({notificationsCount: notificationsCount});
    }

    render() {
        return <div id="container" >
            <Route>
                <AppHeaderMobile
                    onLanguageSelect={this.onLanguageSelect}
                    shoppingListCount={this.state.shoppingListCount}
                    notificationsCount={this.state.notificationsCount}
                />
            </Route>
            <div id="body" >
                <Route render={(props) =>
                    <div id="header" className="">
                        <AppHeader
                            {...props}
                            onLanguageSelect={this.onLanguageSelect}
                            shoppingListCount={this.state.shoppingListCount}
                            notificationsCount={this.state.notificationsCount}
                         />
                    </div>}
                />
                <Route render={() =>
                     <div id="menu" className="underlined">
                         <AppMenu />
                     </div>}
                />
                <Switch>
                    <Route
                        path="/recommendations/:c_code/foods/:p_code/products/:productId"
                        render={(props) =>
                            <ProductDetail
                                {...props}
                                setShoppingListCount={this.setShoppingListCount}
                            />}
                    />
                    <Route path="/recommendations/:c_code/foods/:p_code/products">
                        <RecommendedAldiProducts />
                    </Route>
                    <Route path="/recommendations/:c_code/foods">
                        <RecommendedFoods />
                    </Route>
                    <Route path="/recommendations">
                        <RecommendedClasses />
                    </Route>
                    <Route path="/catalogmenu">
                        <CatalogMenu />
                    </Route>
                    <Route path="/categoryProducts/:categoryId" component={ProductList} />
                    <Route path="/productDetail/:productId" render={(props) => <ProductDetail {...props}
                        setShoppingListCount={this.setShoppingListCount} />} />
                    <Route path="/supplements">
                        <Supplements />
                    </Route>
                    <Route path="/intake">
                        <Intake />
                    </Route>
                    <Route path="/anthropometrics" component={Anthropometrics} />
                    <Route path="/metabolomics">
                        <Metabolomics />
                    </Route>
                    <Route path="/genetics">
                        <Genetics />
                    </Route>
                    <Route path="/notifications">
                        <Notifications setNotificationsCount={this.setNotificationsCount} />
                    </Route>
                    <Route path="/shoppinglist" render={(props) => <ShoppingList {...props}
                        setShoppingListCount={this.setShoppingListCount} />} />
                    <Route path="/orders/:orderId">
                        <Order />
                    </Route>
                    <Route path="/orders">
                        <OrderList />
                    </Route>
                    <Route path="/subcategory/:categoryId" component={CatalogSubcategories} />
                    <Route path="/subcategory-products/:subcategoryId" component={SubcategoryProducts} />
                    <Route path="/searchResults" component={SearchResults} />
                    <Route path="/profile">
                        <Profile />
                    </Route>
                    <Route path="/faq">
                        <Faq />
                    </Route>
                    <Route path="/">
                        <Dashboard />
                    </Route>
                </Switch>
            </div>
            <div id="footer" >
                <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    <AppFooter />
                </Col>
            </div>
        </div>
    }
}

export default ContentLayout;