export default class AccessTokenDao {
    key = 'access-token';

    get() {
        return localStorage.getItem(this.key);
    }

    getAuth0Token() {
        return localStorage.getItem(this.key).replace('bearer ', '');
    }

    save(accessToken) {
        localStorage.setItem(this.key, 'bearer ' + accessToken);
    }
}