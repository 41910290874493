import CategoryDao from "../dao/CategoryDao";
import ProductDao from "../dao/ProductDao";

class SubcategoryProductsBo {
    productDao = new ProductDao();
    categoryDao = new CategoryDao();

    getSubcategoryProducts(categoryId, callback, error) {
        this.productDao.findByAldiCategory(categoryId, callback, error);
    }

    getCategory(categoryId, callback, error) {
        this.categoryDao.findByAldiId(categoryId, callback, error);
    }
}

export default SubcategoryProductsBo;