import React from 'react';
import { Link } from "react-router-dom";
import { Button, Row, Col, Tooltip } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import Carousel from './ProductsCarousel'
import CategoryDao from '../dao/CategoryDao';
import ProductDao from '../dao/ProductDao';
import ShoppingListDao from '../dao/ShoppingListDao_v2';
import CartProductCounter from '../dao/CartProductCounter';
import NutritionInformation from './NutritionInformation';
import Ingredients from './Ingredients';
import ComercialInformation from './ComercialInformation';
import { Spin } from 'antd';
import './ProductDetail.css'
import { FormattedMessage, injectIntl } from 'react-intl';
import MyBreadcrumbs from './MyBreadcrumbs';
import { withUseServingsClassesHook } from '../HOC/withUseServingsClassesHook';

class ProductDetail extends React.Component {
    productDao = new ProductDao();
    categoryDao = new CategoryDao();
    shoppingListDao = new ShoppingListDao();
    cartProductCounter = new CartProductCounter();
    ready = false;
    product = null;
    counter = 0;
    categoryId;
    categoryName;
    subcategoryId;
    subcategoryName;

    constructor(props) {
        super(props);
        this.url = props.location.pathname;
        this.productBaseUrl = this.url.slice(0, this.url.lastIndexOf("/") + 1);
        this.c_code = props.match.params.c_code;
        this.p_code = props.match.params.p_code;
        this.state = {};
        this.onHandleAddToCart = this.onHandleAddToCart.bind(this);
        this.onLoadedProduct = this.onLoadedProduct.bind(this);
        this.onError = this.onError.bind(this);
        this.findByPreventomicsCodeError = this.findByPreventomicsCodeError.bind(this);
        this.findByPreventomicsCodeCallback = this.findByPreventomicsCodeCallback.bind(this);
        this.getCategoryNameCallback = this.getCategoryNameCallback.bind(this);
        this.getSubcategoryNameCallback = this.getSubcategoryNameCallback.bind(this);
        this.refresh = this.refresh.bind(this);
        this.buildBreadcrumbs = this.buildBreadcrumbs.bind(this);
    }

    componentDidUpdate(){
        // Get the servings class of the product
        var elem = Object.entries(this.props.servingsClasses).find(([c_code, c_value]) =>
            c_value.p_codes.includes(this.product._source.p_code)
        )
        if (elem) {
            this.c_code = elem[0];
            this.servingsClass = elem[1];
        }
    }

    getIndexProduct() {
        for (let i = 0; i < this.categoryProducts.length; i++) {
            if (this.categoryProducts[i]._id === this.props.match.params.productId) {
                return i;
            }
        }
        return undefined;
    }

    onHandleAddToCart() {
        this.shoppingListDao.add(this.product._id);
        this.cartProductCounter.refresh();
        this.props.setShoppingListCount({value: 1, increment: true});
    }

    getPreviousProductIndex() {
        const currentIndex = this.getIndexProduct();
        return currentIndex - 1;
    }

    getNextProductIndex() {
        const currentIndex = this.getIndexProduct();
        return currentIndex + 1;
    }

    refresh() {
        this.setState({ status: 'not-ready' });
    }

    getLeftArrow() {
        const previousIndex = this.getPreviousProductIndex();
        if (previousIndex === -1) {
            return <span>&nbsp;</span>
        }
        const productId = this.getProductIdByIndex(previousIndex);
        const productName = this.getProductNameByIndex(previousIndex);
        return <Tooltip placement="bottom" title={productName}>
            <Link onClick={this.refresh} to={this.productBaseUrl + productId}>
                <div id="productdetail-navigation-bar-arrow">
                    <CaretLeftOutlined className="general-icon" style={{ fontSize: '2em' }} />
                    <FormattedMessage id="productdetail.previousproduct" />
                </div>
            </Link >
        </Tooltip>
    }

    getRightArrow() {
        const nextIndex = this.getNextProductIndex();
        if (nextIndex === this.categoryProducts.length) {
            return <span>&nbsp;</span>
        }
        const productId = this.getProductIdByIndex(nextIndex);
        const productName = this.getProductNameByIndex(nextIndex);
        return <Tooltip placement="bottom" title={productName}>
            <Link onClick={this.refresh} to={this.productBaseUrl + productId}>
                <div id="productdetail-navigation-bar-arrow">
                    <FormattedMessage id="productdetail.nextproduct" />
                    <CaretRightOutlined className="general-icon" style={{ fontSize: '2em' }} />
                </div>
            </Link>
        </Tooltip>
    }

    hasTopCategory(subcategoryId) {
        return subcategoryId % 1000 !== 0;
    }

    buildBreadcrumbs() {
        var myBreadcrumbs;

        if (this.url.includes("recommendations")) {
            myBreadcrumbs = [
                {
                    label: 'mybreadcrumbs.start',
                    url: '/',
                },
                {
                    label: 'mybreadcrumbs.recommendedproducts',
                    url: '/recommendations'
                },
                {
                    label: this.c_code,
                    url: `/recommendations/${this.c_code}/foods`
                },
                {
                    label: this.p_code,
                    url: `/recommendations/${this.c_code}/foods/${this.p_code}/products`
                },
                {
                    label: 'mybreadcrumbs.productdetail'
                }
            ];
        } else {
            myBreadcrumbs = [
                {
                    label: 'mybreadcrumbs.start',
                    url: '/',
                },
                {
                    label: 'mybreadcrumbs.catalogmenu',
                    url: '/catalogmenu',
                }
            ];
            if (this.hasTopCategory(this.subcategoryId)) {
                myBreadcrumbs.push(
                    {
                        'label': this.categoryName,
                        'url': '/subcategory/' + this.categoryId
                    });
            }
            myBreadcrumbs.push(
                {
                    'label': this.subcategoryName,
                    'url': '/subcategory-products/' + this.subcategoryId
                },
                {
                    label: 'mybreadcrumbs.productdetail'
                });    
        }

        return myBreadcrumbs;
    }

    getProductDetailContent() {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                {<MyBreadcrumbs data={this.buildBreadcrumbs()} />}
                <Row id="productdetail">
                    <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18} id='productdetail-comercialinformation'>
                        <ComercialInformation data={this.product} />
                        <div id="productdetail-navigation-bar">
                            {this.getLeftArrow()}
                            {this.getRightArrow()}
                        </div>
                        <Carousel
                            onClick={this.refresh}
                            currentProductId={this.props.match.params.productId}
                            categoryId={this.product._source.preventomic_code}
                            howMany={10}
                            productBaseUrl={this.productBaseUrl}
                        />
                    </Col >
                    <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6} id='productdetail-moreinformation'>
                        {this.servingsClass &&
                            <p className="preventomics-recommendation">
                                <FormattedMessage
                                    id="productdetail.recommendation"
                                    values={{
                                        span: msg => <span id='remember'>{msg}</span>,
                                        min: this.servingsClass['health_min_serving'],
                                        max: this.servingsClass['health_max_serving'],
                                        category: formatMessage({ id: this.c_code }).toLowerCase(),
                                        time: this.servingsClass['temporal_measure']
                                    }}
                                /> 
                            </p>
                        }
                        <Button className="add-cart-button" type="primary" onClick={this.onHandleAddToCart}>
                            <FormattedMessage id="productdetail.add" />
                        </Button>
                        <Ingredients data={this.product} />
                        <NutritionInformation data={this.product} />
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={3} xl={4} xxl={5}>&nbsp;</Col>
                </Row >
            </div>
        );
    }

    getLoadingContent() {
        return <Spin size="large" style={{ minHeight: '20em' }} />
    }

    isReady() {
        return this.state.status === 'ready';
    }

    getProductIdByIndex(index) {
        if (index >= 0) {
            return this.categoryProducts[index]._id;
        }
        return undefined;
    }

    getProductNameByIndex(index) {
        if (index >= 0) {
            return this.categoryProducts[index]._source.PRODUCTO;
        }
        return undefined;
    }

    onError(errorResponse) {
        console.error(errorResponse);
    }

    // TODO: Remove callback hell
    findByPreventomicsCodeCallback(categoryProducts) {
        this.categoryProducts = categoryProducts;
        this.setState({ status: 'ready' });
    }

    findByPreventomicsCodeError(error) {
        console.error(error);
    }

    onLoadedProduct(jsonProduct) {
        this.product = jsonProduct;
        this.categoryId = this.product._source.WGR_NR;
        this.subcategoryId = this.product._source['Concatenate Columns ABCD'];

        this.categoryDao.findByAldiId(this.categoryId * 1000, this.getCategoryNameCallback, this.onError);
    }

    getCategoryNameCallback(data) {
        this.categoryName = data?._source.WGR_BEZEICHNUNG;

        this.categoryDao.findByAldiId(this.subcategoryId, this.getSubcategoryNameCallback, this.onError);
    }

    getSubcategoryNameCallback(data) {
        this.subcategoryName = data?._source.WGR_BEZEICHNUNG;
        
        this.productDao.findByPreventomicsCode(this.product._source.preventomic_code
            , this.findByPreventomicsCodeCallback, this.findByPreventomicsCodeError);
    }

    render() {
        // If the user clicked the back/forward buttons of the browser we cannot call refresh() but the product id passed in the props changes
        // so we trigger a new product fetch
        if (this.isReady() && this.product._id === this.props.match.params.productId) {
            return this.getProductDetailContent();
        }
        console.log('productId: ' + this.props.match.params.productId);
        this.productDao.findById(this.props.match.params.productId)
            .then(prod => {
                this.onLoadedProduct(prod);
            })
            .catch(error => this.onError(error));
        return this.getLoadingContent();
    }
}

export default injectIntl(withUseServingsClassesHook(ProductDetail));