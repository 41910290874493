import { useState, useEffect } from 'react';
import AccessTokenDao from '../dao/AccessTokenDao';
import UserDataDao from '../dao/UserDataDao';

export function useServingsClasses() {
    const [servingsClasses, setServingsClasses] = useState({});

    useEffect(() => {
        const userDataDao = new UserDataDao();
        const userId = userDataDao.getPreventomicsId();    
        const accessTokenDao = new AccessTokenDao();
        const urlDSSRecommendations = `${process.env.REACT_APP_DSS}/recommendations/${userId}`;
        const urlDSSGetFFQFoods = `${process.env.REACT_APP_DSS}/KnowledgeDB/FFQ_foods`;

        const classes = {
            c_other: "other",
            c_dairy: "dairy",
            c_eggs: "eggs",
            c_fish: "fish",
            c_fruits: "fruits",
            c_legumes: "legumes",
            c_wmeat: "wmeat",
            c_rmeat: "rmeat",
            c_water: "water",
            c_nuts: "nuts",
            c_fats: "fats",
            c_snacks: "snacks",
            c_starchy: "starchy",
            c_sweets: "sweets",
            c_veg: "veg",
            c_neutral: "neutral"
        }

        const clusters = {
            'Carbohydrate': "carbohydrate",
            'Carbohydrates': "carbohydrate",
            'Lipid': "lipid",
            'Inflammation': "inflammation",
            'OxidativeStress': "oxidativestress",
            'Microbiota': "microbiota"
        }

        const images = {
            /*c_other:   */
            c_dairy: "dairy.jpg",
            c_eggs: "eggs.jpg",
            c_fish: "fish.jpg",
            c_fruits: "fruits.jpg",
            c_legumes: "legumes.jpg",
            c_wmeat: "white-meat.jpg",
            c_rmeat: "red-meat.jpg",
            c_water: "water.jpg",
            c_nuts: "nuts.jpg",
            c_fats: "fats.png",
            c_snacks: "snacks.jpg",
            c_starchy: "starchy.png",
            c_sweets: "sweets.jpg",
            c_veg: "vegetable.png"
            /*c_neutral:   */
        };

        const icons = {
            /*c_other:   */
            c_dairy: "dairy.svg",
            c_eggs: "eggs.svg",
            c_fish: "fish.svg",
            c_fruits: "fruits.svg",
            c_legumes: "legumes.svg",
            c_wmeat: "white-meat.svg",
            c_rmeat: "red-meat.svg",
            c_water: "water.svg",
            c_nuts: "nuts.svg",
            c_fats: "fats.svg",
            c_snacks: "snacks.svg",
            c_starchy: "starchy.svg",
            c_sweets: "sweets.svg",
            c_veg: "vegetable.svg"
            /*c_neutral:   */
        };

        const nutritional_info = {
            c_other: {},
            c_dairy: { textid: "recommendedfoods.dairy", image: ""},
            c_eggs: { textid: "recommendedfoods.eggs", image: ""},
            c_fish: { textid: "recommendedfoods.fish", image: "PREVENTOMICS_grafic_plat_v4.png"},
            c_fruits: { textid: "recommendedfoods.fruits", image: ""},
            c_legumes: { textid: "recommendedfoods.legumes", image: "PREVENTOMICS_grafic_plat_v4.png"},
            c_wmeat: { textid: "recommendedfoods.wmeat", image: "PREVENTOMICS_grafic_plat_v4.png"},
            c_rmeat: { textid: "recommendedfoods.rmeat", image: ""},
            c_water: { textid: "recommendedfoods.water", image: "" },
            c_nuts: { textid: "recommendedfoods.nuts", image: ""},
            c_fats: { textid: "recommendedfoods.fats", image: ""},
            c_snacks: { textid: "recommendedfoods.snacks", image: ""},
            c_starchy: { textid: "recommendedfoods.starchy", image: "PREVENTOMICS_grafic_plat_v4.png"},
            c_sweets: { textid: "recommendedfoods.sweets", image: "" },
            c_veg: { textid: "recommendedfoods.veg", image: "PREVENTOMICS_grafic_plat_v4.png"},
            c_neutral: {}
        }

        const token = {
            headers: {
                'authorization': accessTokenDao.get()
            }
        };

        function mapRecommendationsToServingsClasses(recommendations, cluster, ffqFoodmap, healthyServings) {
            var servingsClasses = {};
            var p_code;
            var c_code;

            Object.values(ffqFoodmap).forEach(item => {
                p_code = item['preventomics_equivalence'];
                if (recommendations.includes(p_code)) {
                    c_code = item['servings_class'];
                    if (!servingsClasses[c_code]) {
                        servingsClasses[c_code] = {
                            p_codes: [],
                            ...healthyServings[c_code],
                            image: images[c_code],
                            icon: icons[c_code],
                            nutritional_info: nutritional_info[c_code],
                            cluster_recommendations_textid: `recommendedfoods.${classes[c_code]}.${clusters[cluster]}`
                        };
                    }
                    if (!servingsClasses[c_code]['p_codes'].includes(p_code)) {
                        servingsClasses[c_code]['p_codes'].push(p_code);
                    }
                }
            });
            setServingsClasses(servingsClasses);
        };

        Promise.all([
            fetch(urlDSSRecommendations, token),
            fetch(urlDSSGetFFQFoods, token)
        ])
        .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
        .then(([recommendationsData, ffqFoodsData]) => {
            mapRecommendationsToServingsClasses(
                recommendationsData.contents.Food_recommendation.Recommended_food,
                recommendationsData.contents.Cluster,
                ffqFoodsData.ffq_foods.DSS_FFQ_foodmap.contents,
                ffqFoodsData.ffq_foods.Healthy_servings.contents
            );
        })
        .catch(error => {
            console.log(error);
        });
    }, []);

    return servingsClasses;
}