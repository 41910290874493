import AccessTokenDao from './AccessTokenDao';

export default class CategoryDao {
    accessTokenDao = new AccessTokenDao();

    findAllPreventomicsCategories(callback, error) {
        const url = process.env.REACT_APP_ELASTICSEARCH_FILTER + '/api';
        const data = {
            method: 'post',
            body: `{
                "httpMethod": "get",
                "context": "/preventomics-categories/_search",
                "queryString": "?q=*&size=`+ process.env.REACT_APP_ELASTICSEARCH_SIZE + `"
            }`,
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson);
                const json = responseJson.hits.hits.map(h => ({
                    id: h._source.Code,
                    name: h._source.Cat_0 + ' / ' + h._source.Cat_1 + ' / ' + h._source.Cat_2
                }));
                // console.log(JSON.stringify(json));
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    findAllAldiCategories(callback, error) {
        const url = process.env.REACT_APP_ELASTICSEARCH_FILTER + '/api';
        const data = {
            method: 'post',
            body: `{
                "httpMethod": "get",
                "context": "/aldi-categories-final-v1/category/_search",
                "queryString": "?q=*&sort=WGR_BEZEICHNUNG.keyword&size=`+ process.env.REACT_APP_ELASTICSEARCH_SIZE + `"
            }`,
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson);
                const json = responseJson.hits.hits;
                // console.log(JSON.stringify(json));
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    findAllAldiSuperCategories(callback, error) {
        const url = process.env.REACT_APP_ELASTICSEARCH_FILTER + '/api';
        const data = {
            method: 'post',
            body: `{
                "httpMethod": "get",
                "context": "/aldi-categories-final-v1/category/_search",
                "queryString": "?q=WGR_UGR1_NR:0+AND+WGR_UGR2_NR:0+AND+WGR_UGR3_NR:0&sort=WGR_BEZEICHNUNG.keyword&size=`+ process.env.REACT_APP_ELASTICSEARCH_SIZE + `"
            }`,
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson);
                const json = responseJson.hits.hits;
                // console.log(JSON.stringify(json));
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    findById(id, callback, error) {
        const url = process.env.REACT_APP_ELASTICSEARCH_FILTER + '/api';
        const data = {
            method: 'post',
            body: `{
                "httpMethod": "get",
                "context": "/preventomics-categories/_search",
                "queryString": "?q=*&size=`+ process.env.REACT_APP_ELASTICSEARCH_SIZE + `"
            }`,
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson);
                const json = responseJson.hits.hits.filter(c => '' + c._source.Code === '' + id)
                    .map(h => ({
                        id: h._source.Code,
                        name: h._source.Cat_0 + ' / ' + h._source.Cat_1 + ' / ' + h._source.Cat_2
                    }));

                // console.log(JSON.stringify(json));
                callback(json[0]);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    findByAldiId(id, callback, error) {
        const url = process.env.REACT_APP_ELASTICSEARCH_FILTER + '/api';
        const data = {
            method: 'post',
            body: `{
                "httpMethod": "get",
                "context": "/aldi-categories-final-v1/category/_search",
                "queryString": "?q=Concatenate:`+ id + `&size=`+ process.env.REACT_APP_ELASTICSEARCH_SIZE + `"
            }`,
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                const json = responseJson.hits.hits;
                callback(json[0]);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    findAldiCategoriesByCategoryId(categoryId, callback, error) {
        const url = process.env.REACT_APP_ELASTICSEARCH_FILTER + '/api';
        const data = {
            method: 'post',
            body: `{
                "httpMethod": "get",
                "context": "/aldi-categories-final-v1/category/_search",
                "queryString": "?q=WGR_NR:`+ categoryId + `&sort=WGR_BEZEICHNUNG.keyword&size=`+ process.env.REACT_APP_ELASTICSEARCH_SIZE + `"
            }`,
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                const json = responseJson.hits.hits;
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    findRecommendedCategoriesByUserId(userId, callback, error) {
        const url = process.env.REACT_APP_DSS + '/recommendations/' + userId;
        // console.log('check url: ' + url);
        const data = {
            headers: {
                'Accept': 'application/json',
                'authorization': this.accessTokenDao.get()
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log('DSS categories: ' + JSON.stringify(responseJson));
                console.log('TODO: check this response');
                const json = responseJson.contents.Food_recommendation.Recommended_food;
                // console.log(JSON.stringify(json));
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    getRecommendedAndDisallowedFood(userId) {
        const url = `${process.env.REACT_APP_DSS}/recommendations/${userId}`;
        const data = {
            headers: {
                'Accept': 'application/json',
                'authorization': this.accessTokenDao.get()
            }
        };
        return fetch(url, data)
            .then(response => response.json())
            .then(responseJson => {
                return responseJson.contents.Food_recommendation;
            })
            .catch(errorResponse => {
                console.log(errorResponse);
            });
    }
}