import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import './Subtitle.css';

class Subtitle extends Component {

    render() {
        return <div id="subtitle">
            <FormattedMessage id="contentlayout.subtitle" />
        </div>;
    }
}

export default Subtitle;