export default class ProductDao {

    findRecommendedProductsByUserId(userId, categoryId, callback, error) {
        console.log('TODO: findRecommendedCategoriesByUserId');
        this.findByPreventomicsCode(categoryId, callback, error);
    }

    findByPreventomicsCode(categoryId, callback, error) {
        const url = process.env.REACT_APP_ELASTICSEARCH_FILTER + '/api';
        const data = {
            method: 'post',
            body: `{
                "httpMethod": "get",
                "context": "/aldi-products-spanish-final-v1/product/_search",
                "queryString": "?q=preventomic_code:`+ categoryId + `&size=` + process.env.REACT_APP_ELASTICSEARCH_SIZE + `"
            }`,
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(JSON.stringify(responseJson));
                const json = responseJson.hits.hits;
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    findByAldiCategory(categoryId, callback, error) {
        const url = process.env.REACT_APP_ELASTICSEARCH_FILTER + '/api';
        const data = {
            method: 'post',
            body: `{
                "httpMethod": "post",
                "context": "/aldi-products-spanish-final-v1/product/_search",
                "queryString": "?size=` + process.env.REACT_APP_ELASTICSEARCH_SIZE + `",
                "body" : "{ \\"query\\": { \\"match\\": { \\"Concatenate Columns ABCD\\": { \\"query\\": \\"` + categoryId + `\\" } } } }"
            }`,
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(JSON.stringify(responseJson));
                const json = responseJson.hits.hits;
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    findAll(callback, error) {
        const url = process.env.REACT_APP_ELASTICSEARCH_FILTER + '/api';
        const data = {
            method: 'post',
            body: `{
                "httpMethod": "get",
                "context": "/aldi-products-spanish-final-v1/product/_search",
                "queryString": "?q=*&size=` + process.env.REACT_APP_ELASTICSEARCH_SIZE + `"
            }`,
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(JSON.stringify(responseJson));
                const json = responseJson.hits.hits;
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }

    findById(productId) {
        const url = process.env.REACT_APP_ELASTICSEARCH_FILTER + '/api';
        const bodyString = JSON.stringify({
            httpMethod: "get",
            context: "/aldi-products-spanish-final-v1/product/_search",
            queryString: "?q=_id:" + productId
        });
        const data = {
            method: 'post',
            body: bodyString,
            headers: {
                "Content-Type": "application/json"
            }
        };
        return fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => responseJson.hits.hits[0])
            .catch((errorResponse) => {
                console.log(errorResponse);
            });
    }

    findByProductName(productNameQuery, callback, error) {
        const url = process.env.REACT_APP_ELASTICSEARCH_FILTER + '/api';
        const parsedProductNameQuery = productNameQuery.trim().split(/\s+/).join(" AND ");
        const data = {
            method: 'post',
            body: JSON.stringify({
                httpMethod: "get",
                context: "/aldi-products-spanish-final-v1/product/_search",
                queryString: encodeURI(`?q=PRODUCTO.spanish:${parsedProductNameQuery}&size=${process.env.REACT_APP_ELASTICSEARCH_SIZE}`)
            }),
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(url, data)
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(JSON.stringify(responseJson));
                const json = responseJson.hits.hits;
                callback(json);
            })
            .catch((errorResponse) => error(errorResponse));
    }
}