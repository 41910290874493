import React, { Component } from 'react';
import './Instructions.css';

export default class Instructions extends Component {

    isThereData() {
        if (this.props.data === undefined) {
            return false;
        }
        if (this.props.data === null) {
            return false;
        }
        if (this.props.data.trim() === '') {
            return false;
        }
        if (this.props.data.trim().toLowerCase() === 'null') {
            return false;
        }
        return true;
    }

    render() {
        if (!this.isThereData()) {
            return null;
        }
        return <div id='instructions-container'>
            <div id='instructions-title'>{this.props.field}</div>
            <div id='instructions-data'>{this.props.data}</div>
        </div >
    }
}