import React from 'react';
import { Avatar, Tooltip, List } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './ShoppingList.css'

export default function ShoppingSubList(props) {

    function isGenericProduct(id) {
        return id >= 1000000;
    }

    return (
        <div>
            {props.products.length > 0 &&
                <div>
                    {props.title}
                    <List
                        dataSource={props.products}
                        renderItem={item => (
                            <List.Item
                                className="orderlist-item"
                                actions={[
                                    <div style={{padding: '0.5em'}}>
                                        <Tooltip placement="bottom" title={<FormattedMessage id="shoppinglist.removeone" />}>
                                            <MinusCircleOutlined
                                                className="delete-product"
                                                onClick={() => { props.onClickRemoveOne(item._id) }}
                                                onMouseDown={ (e) => e.preventDefault() }
                                            />
                                        </Tooltip>
                                        <span style={{padding: '0 1em'}}>
                                            {props.howMany[item._id]}
                                        </span>
                                        <Tooltip placement="bottom" title={<FormattedMessage id="shoppinglist.addone" />}>
                                            <PlusCircleOutlined
                                                className="delete-product"
                                                onClick={() => { props.onClickAddOne(item._id) }}
                                                onMouseDown={ (e) => e.preventDefault() }
                                            />
                                        </Tooltip>
                                    </div>,
                                    <Tooltip placement="bottom" title={<FormattedMessage id="shoppinglist.removeall" />}>
                                        <DeleteOutlined
                                            style={{padding: '0.5em'}}
                                            className="delete-product"
                                            onClick={() => { props.onClickRemoveAll(item._id) }}
                                        />
                                    </Tooltip>
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar src={item._source.Imagen} size={60} />
                                    }
                                    title={
                                        isGenericProduct(item._id)
                                            ? <span>{item._source.PRODUCTO} {item._source.Marca}</span> 
                                            : <Link to={'/productDetail/' + item._id}>
                                                {item._source.PRODUCTO} {item._source.Marca && item._source.Marca !== "NULL" && item._source.Marca}
                                              </Link>
                                    }
                                />
                            </List.Item>
                        )}
                    >
                    </List>
                </div>
            }
        </div>
    );
}
