import React, { Component } from 'react';
import ProductItem from './ProductItem';
import ProductDao from '../dao/ProductDao';
import CategoryDao from '../dao/CategoryDao';
import { Row, Col, Spin } from 'antd';
import ContentTitle from './ContentTitle';
import Empty from './MyEmpty';

export default class ProductList extends Component {
    productDao = new ProductDao();
    categoryDao = new CategoryDao();

    constructor(props) {
        super(props);
        this.state = {
            productsList: [],
            categoryData: {
                name: 'Unknown'
            }
        };
        this.onLoadedProducts = this.onLoadedProducts.bind(this);
        this.onLoadedCategory = this.onLoadedCategory.bind(this);
        this.onError = this.onError.bind(this);
    }

    onLoadedCategory(categoryData) {
        // console.log(JSON.stringify(categoryData));
        this.setState({
            productsList: this.state.productsList,
            categoryId: this.props.match.params.categoryId,
            categoryData: categoryData,
        }, function () {
            this.productDao.findByPreventomicsCode(this.props.match.params.categoryId
                , this.onLoadedProducts, this.onError);
        });
    }

    onError(errorResponse) {
        console.error(errorResponse);
    }

    onLoadedProducts(jsonProducts) {
        // console.log('onLoadedProducts');
        this.setState({
            categoryId: this.state.categoryId,
            categoryData: this.state.categoryData,
            productsList: jsonProducts
        });
    }

    getContent() {
        if (this.state.productsList.length === 0) {
            return this.getEmptyContent();
        }
        return <Row style={{ minHeight: '20em' }}>
            <ContentTitle text={this.state.categoryData.name + '(' + this.state.categoryData.id + ')'} />
            {
                this.state.productsList.map(item => (
                    <Col key={item._id} xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                        <ProductItem item={item} list={this.state.productsList} />
                    </Col >
                ))
            }
        </Row>
    }

    getLoadingContent() {
        return <Spin style={{ minHeight: '20em' }} size="large" />
    }

    getEmptyContent() {
        return <Row style={{ minHeight: '20em' }}>
            <ContentTitle text={this.state.categoryData.name} />
            <Col span={24}>
                <Empty />
            </Col>
        </Row>
    }

    isReady() {
        return this.props.match.params.categoryId === this.state.categoryId;
    }

    render() {
        if (this.isReady()) {
            return this.getContent();
        }
        this.categoryDao.findById(this.props.match.params.categoryId
            , this.onLoadedCategory, this.onError);
        return this.getLoadingContent();
    }
}